// ----------------------------------------------------------------------

// lc - lowercase

// ----------------------------------------------------------------------

const en = {
  /**
   * Common
   */
  app_name: `RewardUp Dashboard`,
  copyright: `© 2024 RewardUp Inc. All Rights Reserved.`,

  /**
   * Errors
   */
  error_400: `Oops! Something went wrong!`,
  error_404: `Page Not Found`,

  /**
   * Actions
   */
  close: `Close`,

  /**
   * Navigation bar
   */
  home: `Home`,
  main: `Main`,
  dashboard: `Dashboard`,
  members: `Members`,
  campaigns: `Campaigns`,
  notifications: `Notifications`,
  rewards_program: `Rewards Program`,
  point: `Point`,
  points: `Points`,
  point_rules: `Point Rules`,
  reward_levels: `Reward Levels`,
  retention_autopilot: `Retention Autopilot`,
  vouchers: `Vouchers`,
  edit_voucher: `Edit Voucher`,
  vip: `VIP`,
  referrals: `Referrals`,
  custom: `Custom`,
  gifts_program: `Gifts Program`,
  settings: `Settings`,
  manage_collection: `Manage Collection`,
  manage_collections: `Manage Collections`,
  manage_excluded_collections: `Manage Excluded Collections`,
  manage_excluded_items: `Manage Excluded Items`,
  manage_item: `Manage Item`,
  manage_items: `Manage Items`,
  member_site: `Member Site`,
  business: `Business`,
  collection_businesses_added_count: `* {{collectionCount}} Collection added for {{count}} businesses`,
  collections_businesses_added_count: `*{{collectionCount}}  Collections added for {{count}} businesses`,
  item_businesses_added_count: `* {{itemCount}} Item added for {{count}} businesses`,
  items_businesses_added_count: `* {{itemCount}} Items added for {{count}} businesses`,
  add_collection: `Add Collection`,
  add_item: `Add Item`,
  empty_data: `No data`,

  /**
   * Authentication
   */
  sign_in_welcome: `Welcome back,`,
  sign_in_welcome_desc: `Login to access the store dashboard.`,
  new_user: `New user?`,
  create_an_account: `Create an account`,
  email: `Email`,
  email_required: `Email is required`,
  email_invalid: `Email must be a valid email address`,
  password: `Password`,
  password_required: `Password is required`,
  password_min: `Password must have at least {{min_char}} characters`,
  login: `Login`,
  forgot_password: `Forgot password?`,
  forgot_password_desc: `Enter the email address associated with your account and we will email you the verification code`,
  reset_password: `Reset Password`,
  reset_password_desc: `We've sent a 6-digit verification code to {{email}}`,
  send_request: `Send Request`,
  go_back_to_login: `Go back to login`,
  code: `Code`,
  didnt_receive_code: `Didn't receive the code?`,
  resend_code: `Resend Code`,
  passwords_mismatch: `Passwords does not match`,
  confirm_new_password: `Confirm New Password`,

  /**
   * User profile
   */
  logout: `Logout`,
  logout_error: `Unable to logout`,

  /**
   * Programs
   */
  base_point_rules: `Base Point Rules`,
  base_point_rules_desc: `Setup rules for members to earn points`,
  sign_up: `Sign Up`,
  sign_up_desc: `Motivate your members to sign up for your reward program by offering instant points.`,
  active: `Active`,
  inactive: `Inactive`,
  program_details: `Program Details`,
  program_name: `Program Name`,
  program_name_required: `Program name is required`,
  program_description: `Program Description`,
  program_description_required: `Program description is required`,
  earning_points: `Earning Points`,
  earning_points_required: `Earning points is required`,
  points_lc: `points`,
  status: `Status`,
  status_desc: `Enable or disable`,
  program_status: `Status`,
  program_status_desc: `Enable or disable this program`,
  program_eg: `e.g., {{text}}`,
  limit_usage: `Limit Usage`,
  limit_usage_desc: `Restrict the usage to limited number of times`,
  once_in_a_lifetime: `Once in a lifetime`,
  once_in_a_lifetime_limitation_desc: `Limited to only once in a lifetime`,
  for_every: `For every`,
  lifetime: `Lifetime`,
  year: `Year`,
  month: `Month`,
  week: `Week`,
  day: `Day`,
  hour: `Hour`,
  minute: `Minute`,
  usage_period: `Usage Period`,
  usage_count: `Usage Count`,
  times_lc: `times`,
  days_lc: `days`,
  save_changes: `Save Changes`,
  place_order: `Place Order`,
  place_order_desc: `Motivate your members to place order by offering instant points.`,
  earning_type: `Earning Type`,
  amount_spent: `Amount Spent`,
  amount_spent_required: `Amount spent is required`,
  order_placed: `Order Placed`,
  spending_unit: `Spending Unit`,
  minimum_purchase_amount: `Minimum Purchase Amount`,
  refunded: 'Refunded',
  auto_remove_points_desc: `Auto remove points when order is cancelled, refunded or partially refunded`,
  purchase: `Purchase`,
  purchase_desc: `Motivate your members to purchase item by offering instant points.`,
  collection: `Collection`,
  item: `Item`,
  review_desc: `Motivate your members to write review by offering instant points.`,
  review_link: `Review Link`,
  review_link_placeholder: `https://domain.com/username`,
  review_link_required: `Review link is required`,
  birthday_desc: `Motivate your members to provide their birthday by offering instant points.`,
  once_per_year_limitation_desc: `Limited to only once per year`,
  sms_subscription: `SMS Subscription`,
  sms_subscription_desc: `Motivate your members to subscribe to sms by offering instant points.`,
  email_subscription: `Email Subscription`,
  email_subscription_desc: `Motivate your members to subscribe to email by offering instant points.`,
  install_wallet_pass: `Install Wallet Pass`,
  install_wallet_pass_desc: `Motivate your members to download wallet pass to their mobile by offering instant points.`,
  facebook_like: `Like on Facebook`,
  facebook_like_desc: `Motivate your members to like you on facebook by offering instant points.`,
  facebook_link: `Facebook Link`,
  facebook_link_placeholder: `https://facebook.com/username`,
  facebook_link_required: `Facebook link is required`,
  instagram_follow: `Follow on Instagram`,
  instagram_follow_desc: `Motivate your members to follow you on instagram by offering instant points.`,
  instagram_link: `Instagram Link`,
  instagram_link_placeholder: `https://instagram.com/username`,
  instagram_link_required: `Instagram link is required`,
  twitter_follow: `Follow on Twitter`,
  twitter_follow_desc: `Motivate your members to follow you on twitter by offering instant points.`,
  twitter_link: `Twitter Link`,
  twitter_link_placeholder: `https://twitter.com/username`,
  twitter_link_required: `Twitter link is required`,
  tiktok_like: `Like on TikTok`,
  tiktok_like_desc: `Motivate your members to follow you on tiktok by offering instant points.`,
  tiktok_link: `Tiktok Link`,
  tiktok_link_placeholder: `https://tiktok.com/username`,
  tiktok_link_required: `TikTok link is required`,
  manual_approval_desc: `Requires Manual Approval`,
  manual_approval_helper: `Note: You will need to manually approve/reject points for each members.`,
  redeeming_points: `Redeeming Points`,
  redeeming_points_required: `Redeeming points is required`,
  ways_to_redeem_desc: `Setup rewards for members to redeem points`,
  add_new: `Add New`,
  create_reward: `Create Reward`,
  modify_reward: `Modify Reward`,
  new_reward: `New Reward`,
  reward_status: `Status`,
  reward_status_desc: `Enable or disable this reward`,
  reward_details: `Reward Details`,
  reward_desc: `Allow your members to redeem their points for instant rewards`,
  amount: `Amount`,
  percentage: `Percentage`,
  discount_amount: `Discount Amount`,
  discount_percentage: `Discount Percentage`,
  discount_target: `Discount Target`,
  apply_discount_to: `Apply discount to`,
  order: `Order`,
  minimum_order_amount: `Minimum Order Amount (optional)`,
  minimum_order_amount_helper: `Note: Leave it empty if you don't want to apply the restriction.`,
  per: `per`,
  collections: `Collections`,
  items: `Items`,
  referral_program: `Referral Program`,
  referral_program_desc: `Grow your customer base and reward existing members for referring their friends.`,
  member_reward: `Member Reward`,
  referring_member_reward: `Member Reward`,
  member_reward_desc: `Reward your existing members for referring their friends`,
  member_reward_description_placeholder: `Eg: Get $5 Off on each & every successful referral`,
  referral_program_settings: `Referral Program Settings`,
  referral_reward_details: `Referral Reward Details`,
  redeemed_count: `{{count}} redeemed`,
  friend_reward: `Friend Reward`,
  referred_friend_reward: `Friend Reward`,
  friend_reward_desc: `Reward the new members for signing up using their friend's referral code`,
  friend_reward_description_placeholder: `Eg: Get 10% Off on your first visit/purchase`,
  social_sharing: `Social Sharing`,
  social_sharing_title_placeholder: `Get 10% OFF at {{businessName}}`,
  social_sharing_message_placeholder: `Join {{businessName}} rewards and get 10% OFF on your first order. Click the link to signup. With Love, {{member_first_name}}.`,
  social_sharing_desc: `Members can share their referral link directly through SMS, Whatsapp, Facebook & more.`,
  modify_social_sharing_settings: `Modify Social Sharing Settings`,
  title: `Title`,
  title_required: `Title is required`,
  description: `Description`,
  message: `Message`,
  icon: `Icon`,
  icon_desc: `Choose an icon or upload your own`,
  eligible_period: `Eligible Period`,
  eligible_period_desc: `Select the date period for the eligibility`,
  start_date: `Start Date`,
  end_date: `End Date`,
  eligible_days: `Eligible Days`,
  eligible_days_desc: `Select the days of the week for the eligibility`,
  days: `Days`,
  all_day: `All Day`,
  start_time: `Start Time`,
  end_time: `End Time`,
  customer_reward: `Customer Reward`,
  reward_type: `Reward Type`,
  vip_program: `VIP Program`,
  vip_tiers: `VIP Tiers`,
  vip_tiers_desc: `Setup VIP tiers to reward your most loyal members while increasing your average customer lifetime value`,
  create_vip_tier: `Create VIP Tier`,
  modify_vip_tier: `Modify VIP Tier`,
  customer_count: `{{count}} members`,
  vip_tier: `VIP Tier`,
  new_tier: `New Tier`,
  edit_tier: `Edit Tier`,
  tier_details: `Tier Details`,
  tier_details_format: `{{tier_name}} - Tier Details`,
  tier_desc: `Configure the milestone required to achieve the tier`,
  tier_name: `Tier Name`,
  tier_name_required: `Tier name is required`,
  tier_description: `Tier Description`,
  tier_description_required: `Tier description is required`,
  milestone: `Milestone`,
  milestone_points_required: `Milestone points is required`,
  milestone_points_helper_base_tier: `Note: Base tier always has the milestone of 0 points`,
  milestone_amount_spent_helper_base_tier: `Note: Base tier always has the milestone of $0`,
  remove_tier: `Remove Tier`,
  tier_points: `Tier Points`,
  tier_points_desc: `Tier members will be issued points based on the below program.`,
  tier_points_empty: `No points program has been added`,
  tier_rewards: `Tier Rewards`,
  tier_rewards_desc: `Add entry rewards that will be unlocked once a customer enters this tier.`,
  tier_rewards_empty: `No rewards has been added`,
  create_tier_reward: `Create Tier Reward`,
  modify_tier_reward: `Modify Tier Reward`,
  tier_reward_details: `Tier Reward Details`,
  tier_reward_desc: `Give your members instant rewards for achieving the tier milestone.`,
  tier_perks: `Tier Perks`,
  tier_perks_desc: `Add additional perks or benefits to tier members.`,
  tier_perks_empty: `No perks has been added`,
  modify_tier_perks: `Modify Tier Perks`,
  perk_count: `Perk {{count}}`,
  add_perk: `Add Perk`,
  add_reward: `Add Reward`,
  reward_name: `Reward Name`,
  reward_name_required: `Reward name is required`,
  reward_description: `Reward Description`,
  reward_description_required: `Reward description is required`,
  new_entry_reward: `New Entry Reward`,
  vip_program_settings: `VIP Program Settings`,
  vip_program_desc: `Configure the program start date and how your members advance through VIP tiers.`,
  program_start_date: `Program Start Date`,
  vip_tier_type: `VIP Tier Type`,
  points_earned: `Points Earned`,
  vip_tier_validity: `VIP Tier Validity`,
  one_calendar_year: `One Calendar Year`,
  eligible_vip_tiers: `Eligible VIP Tiers`,
  eligible_vip_tiers_desc: `Limit to members based on VIP tiers`,
  expiry: `Expiry`,
  reward_expiry: `Reward Expiry`,
  reward_expiry_desc: `Expire issued rewards after an elapsed time`,
  months_6: `6 months (recommended)`,
  year_1: `1 year`,
  members_list: `Members List`,
  member: `Member`,
  new_member: `New Member`,
  add_new_member: `Add New Member`,
  member_details: `Member Details`,
  name: `Name`,
  phone_number: `Phone Number`,
  phone_number_optional: `Phone Number (optional)`,
  phone_number_helper: `Member will use this phone number to earn points and redeem rewards`,
  add_phone_number: `Add Phone Number`,
  edit_phone_number: `Edit Phone Number`,
  email_optional: `Email (optional)`,
  add_email: `Add Email`,
  edit_email: `Edit Email`,
  new_member_desc: `Member will be provided with points & rewards`,
  sms_consent_desc: `Member gave consent for SMS communications`,
  email_consent_desc: `Member gave consent for Email communications`,
  member_count_one: `1 member`,
  member_count_other: `{{count}} members`,
  member_search: `Search by name, phone number or email`,
  visits: `Visits`,
  last_visit: `Last Visit`,
  source: `Source`,
  action: `Action`,
  date: `Date`,
  tier_achieved: `Tier Achieved`,
  members_point_history_search: `Search by action`,
  members_referral_history_search: `Search by name`,
  member_point_history_search: `Search by action`,
  member_referral_history_search: `Search by name`,
  member_vip_history_search: `Search by tier`,
  member_reward_redeemed_history_search: `Search by reward`,
  member_order_history_search: `Search by order`,
  tags_optional: `Tags (optional)`,
  tag_count_one: `1 tag`,
  tag_count_other: `{{count}} tags`,
  point_count_one: `1 point`,
  point_count_other: `{{count}} points`,
  member_profile: `Member Profile`,
  profile: `Profile`,
  add_name: `Add Name`,
  edit_name: `Edit Name`,
  notes: `Notes`,
  notes_placeholder: `eg., Likes white mocha with oat milk`,
  add_notes: `Add Notes`,
  edit_notes: `Edit Notes`,
  current: `Current`,
  add_points: `Add Points`,
  add_points_placeholder: `eg., 100`,
  remove_points: `Remove Points`,
  remove_points_placeholder: `eg., 100`,
  manual_adjustment: `Manual Adjustment`,
  add_points_description_placeholder: `eg., Special Gift`,
  remove_points_description_placeholder: `eg., Order Refunded`,
  reason: `Reason`,
  reason_optional: `Reason (optional)`,
  add_points_reason_placeholder: `eg., Extra points to make customer smile`,
  remove_points_reason_placeholder: `eg., Points removed for cancelling the order`,
  points_reason_placeholder: `For internal use. Not visible to the member.`,
  new_points_balance: `New Points Balance`,
  card_number: `Card# {{cardNumber}}`,
  redeem: `Redeem`,
  available_rewards: `Available Rewards`,
  subscribed: `Subscribed`,
  not_subscribed: `Not Subscribed`,
  unsubscribed: `Unsubscribed`,
  activity: `Activity`,
  source_format: `Source: {{source}}`,
  member_since_format: `Member since: {{memberSince}}`,
  last_visit_format: `Last visit: {{lastVisit}}`,
  last_contacted_format: `Last contacted: {{lastContacted}}`,
  reward: `Reward`,
  rewards: `Rewards`,
  redeem_reward: `Redeem Reward`,
  redeemed_rewards: `Redeemed Rewards`,
  redeemed_user: `Redeemed User`,
  redeemed_location: `Redeemed Location`,
  orders: `Orders`,
  search_3d: `Search...`,
  from_date: `From Date`,
  to_date: `To Date`,
  new: `New`,
  returning: `Returning`,
  top_spending: `Top Spending`,
  lost: `Lost`,
  vip_tier_format: `Vip Tier: {{name}}`,
  activity_history: `Activity History`,
  order_history: `Order History`,
  favorite_food_format: `Favorite: {{items}}`,
  referral_link_format: `Referral Link: `,
  referred_count: `Referred: {{count}}`,
  members_by_segment: `Members by Segment`,
  last_30_days_bkt: `(Last 30 days)`,
  campaigns_list: `Campaigns List`,
  create_campaign: `Create Campaign`,
  all: `All`,
  sms: `SMS`,
  push: `Push`,
  edit_campaign: `Edit Campaign`,
  edit: `Edit`,
  recipients: `Recipients`,
  content: `Content`,
  review: `Review`,
  continue: `Continue`,
  continue_to_content: `Save & Continue to Content`,
  campaign_details: `Campaign Details`,
  campaign_name: `Campaign Name`,
  tags: `Tags`,
  send_to: `Send To`,
  dont_send_to_opt: `Don't Send To (optional)`,
  estimated_recipients: `Estimated Recipients`,
  estimated_recipients_desc: `{{channel}} notification will be sent approximately to the following number of members`,
  estimated_recipients_info: `This estimate removes duplicate members, excluded members, and anyone without {{channel}} consent.`,
  write_message: `Write your message...`,
  message_counter: `{{messageLength}} / {{maximumSegmentLength}} • {{credits}} SMS Credits`,
  preview: `Preview`,
  continue_to_review: `Save & Continue to Review`,
  auto_shorten_url: `Automatically shorten links and email addresses`,
  alert_click_rates_disabled: `Click rates won't be available without shortened links.`,
  compliance: `Compliance`,
  brand_prefix: `Brand Prefix`,
  brand_prefix_desc: `Adds your brand before message content. Recommended by U.S. carriers and required in certain international jurisdictions.`,
  alert_brand_prefix: `Brand prefix is required in certain jurisdictions when sending messages via numeric sending numbers.`,
  unsubscribe_instructions: `Unsubscribe Instructions`,
  unsubscribe_instructions_desc: `Adds instructions to unsubscribe from future communications after message content.`,
  alert_unsubscribe_instructions: `Opt-out text is required in certain jurisdictions and we strongly recommend including it in your messages.`,
  opt_out_text: `Text STOP to opt-out`,
  alert_special_characters: `Special unicode characters (Emojis/Smileys) reduces SMS character limit from 160 to 70, which could increase the message count.`,
  send_to_format: `Send to <bold>{{recipients}}</bold>`,
  exclude_format: `Exclude <bold>{{recipients}}</bold>`,
  estimated_recipients_format: `<bold>{{count}}</bold> estimated recipients`,
  sms_credit_per_recipient_format: `<bold>{{credit}} SMS Credit</bold> estimated per recipient`,
  sms_credit_per_recipient_info: `Due to the length of the content, this campaign will be charged as multiple SMS messages to each recipient. Recipient will see your content as a single message.`,
  tracking: `Tracking`,
  sms_tracking_enabled_desc: `Because you have enabled link shortening, we will track <bold>opens and clicks</bold> for this campaign.`,
  account_credit_limits: `Account Credit Limits`,
  campaign_credit_usage_format: `Sending this campaign will use estimated <bold>{{credit}}</bold> credits, leaving you with <bold>{{balance}}</bold> credits for this billing cycle.`,
  campaign_credit_usage_clover_format: `Sending this campaign will use estimated <bold>{{credit}}</bold> credits and <bold>{{amount}}</bold> will be charged to your Clover billing.`,
  campaign_credit_usage_exceeded_format: `Sending this campaign will use estimated <bold>{{credit}}</bold> credits and you have only <bold>{{balance}}</bold> credits available.`,
  insert_media: `Insert Media`,
  insert_property: `Insert Property`,
  change: `Change`,
  clear: `Clear`,
  merge: `Merge`,
  remove: `Remove`,
  remove_desc: `Are you sure you want to remove?`,
  cancel: `Cancel`,
  campaign_content: `Campaign Content`,
  subject: `Subject`,
  call_to_action_button: `Call to Action (CTA) Button`,
  cta_text_required: `CTA Text is required`,
  cta_url: `CTA URL`,
  text: `Text`,
  background_color: `Background Color`,
  text_color: `Text Color`,
  url: `URL`,
  sender: `Sender`,
  sender_format: `Your campaign will be sent from <bold>{{senderName}} ﹤{{senderEmail}}﹥</bold>`,
  email_tracking_enabled_desc: `We will automatically track <bold>opens and clicks</bold> for this campaign.`,
  email_preview_desc: `You created this variation with the <bold>{{editorName}} editor</bold>.`,
  view_preview: `View Preview`,
  email_campaign_credit_usage_format: `Sending this campaign will use estimated <bold>{{credit}}</bold> credits, leaving you with <bold>{{balance}}</bold> credits for this billing cycle.`,
  unsubscribe_link: `Unsubscribe Link`,
  unsubscribe_link_success_desc: `We couldn't find an unsubscribe link in your email. We will automatically add an unsubscribe link when sending the email if you don't add one.`,
  send_campaign: `Send Campaign`,
  send_campaign_desc: `Select when to send this Campaign`,
  publish_strategy: `Publish Strategy`,
  schedule: `Schedule`,
  now: `Now`,
  send_now: `Send Now`,
  date_time: `Date & Time`,
  email_footer_format: `Sent by <bold>RewardUp</bold> on behalf of <bold>{{businessName}}</bold>`,
  unsubscribe: `Unsubscribe`,
  email_notifications: `Email Notifications`,
  email_notifications_desc: `Notify members whenever they earn points, redeem points and do other reward-related activities.`,
  sms_notifications: `SMS Notifications`,
  sms_notifications_desc: `Notify members whenever they earn points, redeem points and do other reward-related activities.`,
  push_notifications: `Push Notifications`,
  push_notifications_desc: `Notify members whenever they earn points, redeem points and do other reward-related activities.`,
  edit_notification: `Edit Notification`,
  edit_email_notification: `Edit Email Notification`,
  edit_sms_notification: `Edit SMS Notification`,
  edit_push_notification: `Edit Push Notification`,
  subject_required: `Subject is required`,
  content_required: `Content is required`,
  notification_content: `Notification Content`,
  save_exit: `Save & Exit`,
  maximum_discount_amount: `Maximum Discount Amount (optional)`,
  maximum_discount_amount_helper: `Note: Leave it empty if you don't want to apply the restriction.`,
  retention_autopilot_desc: `Bring back the members to your store more often.`,
  automated: `Automated`,
  retention_autopilot_desc_format: `{{visit}} • {{expiry}} day expiration`,
  total_delivered: `Total Delivered`,
  total_visits: `Total Visits`,
  visit_rate: `Visit Rate`,
  edit_retention_autopilot: `Edit Retention Autopilot`,
  retention_autopilot_birthday_desc: `Automatically reward your members on their birthday`,
  retention_autopilot_second_visit_desc: `Automatically reward your first time members`,
  retention_autopilot_slipping_away_desc: `Automatically reward your members who didn't visit for {{count}} days`,
  retention_autopilot_lost_desc: `Automatically reward your members who didn't visit for {{count}} days`,
  eligibility: `Eligibility`,
  retention_autopilot_birthday_eligibility_desc: `Reward will be issued 3 days before their {{validity}}`,
  retention_autopilot_second_visit_eligibility_desc: `Reward will be issued when a member becomes eligible`,
  retention_autopilot_slipping_away_eligibility_desc: `Reward will be issued when a member becomes eligible`,
  retention_autopilot_lost_eligibility_desc: `Reward will be issued when a member becomes eligible`,
  retention_autopilot_birthday_eligibility: `Members who added their birthday <bold>30 days</bold> in advance`,
  retention_autopilot_second_visit_eligibility: `Issue reward after`,
  retention_autopilot_slipping_away_eligibility: `Members who <bold>didn't visit</bold> in the last <bold>{{days}} days</bold>`,
  retention_autopilot_lost_eligibility: `Members who <bold>didn't visit</bold> in the last <bold>{{days}} days</bold>`,
  retention_autopilot_eligibility_notification: `Notification will be sent to the members when they become eligible for this reward`,
  retention_autopilot_birthday_email_notification_desc: `Manage the email notification for the <bold>Birthday</bold> reward`,
  retention_autopilot_birthday_sms_notification_desc: `Manage the sms notification for the <bold>Birthday</bold> reward`,
  retention_autopilot_birthday_push_notification_desc: `Manage the push notification for the <bold>Birthday</bold> reward`,
  retention_autopilot_second_visit_email_notification_desc: `Manage the email notification for the <bold>Second Visit</bold> reward`,
  retention_autopilot_second_visit_sms_notification_desc: `Manage the sms notification for the <bold>Second Visit</bold> reward`,
  retention_autopilot_second_visit_push_notification_desc: `Manage the push notification for the <bold>Second Visit</bold> reward`,
  retention_autopilot_slipping_away_email_notification_desc: `Manage the email notification for the <bold>Slipping Away</bold> reward`,
  retention_autopilot_slipping_away_sms_notification_desc: `Manage the sms notification for the <bold>Slipping Away</bold> reward`,
  retention_autopilot_slipping_away_push_notification_desc: `Manage the push notification for the <bold>Slipping Away</bold> reward`,
  retention_autopilot_lost_email_notification_desc: `Manage the email notification for the <bold>Lost</bold> reward`,
  retention_autopilot_lost_sms_notification_desc: `Manage the sms notification for the <bold>Lost</bold> reward`,
  retention_autopilot_lost_push_notification_desc: `Manage the push notification for the <bold>Lost</bold> reward`,
  birthday_lc: `birthday`,
  birthday_month_lc: `birthday month`,
  validity: `Validity`,
  birthday: `Birthday`,
  birthday_optional: `Birthday (optional)`,
  birthday_helper: `eg., Jan 01`,
  add_birthday: `Add Birthday`,
  edit_birthday: `Edit Birthday`,
  birthday_month: `Birthday Month`,
  add_tags: `Add Tags`,
  add_tags_for_member: `Add Tags for {{member}}`,
  edit_tags: `Edit Tags`,
  edit_tags_for_member: `Edit Tags for {{member}}`,
  remove_tags: `Remove Tags`,
  expires_in: `Expires in`,
  create_voucher: `Create Voucher`,
  voucher_desc: `Provide vouchers for specific promotions or a specific member segment`,
  modify_voucher: `Modify Voucher`,
  eligible_members: `Eligible Members`,
  eligible_members_desc: `Select the member segments for the eligibility`,
  customer_segments: `Customer Segments`,
  retention_autopilot_title_format: `{{type}} - Reward`,
  second_visit: `Second Visit`,
  slipping_away: `Slipping Away`,
  notify: `Notify`,
  notify_desc: `Notify the eligible members about the reward`,
  immediately: `Immediately`,
  earning: `Earning`,
  redeeming: `Redeeming`,
  reward_program: `Reward Program`,
  referral: `Referral`,
  welcome: `Welcome`,
  earn_points: `Earn Points`,
  earn_reward: `Earn Reward`,
  redeem_points: `Redeem Points`,
  vip_tier_achieved: `VIP Tier Achieved`,
  birthday_reward: `Birthday Reward`,
  second_visit_reward: `Second Visit Reward`,
  slipping_away_reward: `Slipping Away Reward`,
  lost_reward: `Lost Reward`,
  top_spending_reward: `Top Spending Reward`,
  wallet_pass: `Wallet Pass`,
  front_side: `Front Side`,
  back_side: `Back Side`,
  display_header_field: `Header Field`,
  display_left_field: `Left Field`,
  display_right_field: `Right Field`,
  label: `Label`,
  value: `Value`,
  information_label: `Information Label`,
  information_value: `Information Value`,
  member_name: `Member Name`,
  member_current_points: `Member Current Points`,
  member_available_rewards: `Member Available Rewards`,
  member_tier: `Member Tier`,
  styling: `Styling`,
  image: `Image`,
  excluded_collections: `Excluded Collections`,
  excluded_items: `Excluded Items`,
  excluded_items_optional: `Excluded Items (optional)`,
  apple_pass_installed: `Apple Pass Installed`,
  apple_pass_uninstalled: `Apple Pass Uninstalled`,
  referred_friend: `Referred Friend`,
  exclude_type: `Exclude Type`,
  exclude_items: `Exclude Items`,
  exclude_items_desc: `Restrict usage of some items`,
  exclude_collections_items: `Exclude Collections/Items`,
  exclude_collections_items_desc: `Restrict usage of some collections or items`,
  redeemed: `Redeemed`,
  redemption_rate: `Redemption Rate`,
  welcome_section: `Welcome Section`,
  action_text: `Action Text`,
  ways_to_earn_section: `Ways to Earn Section`,
  ways_to_redeem_section: `Ways to Redeem Section`,
  digital_card_section: `Digital Card Section`,
  vip_section: `VIP Section`,
  referral_section: `Referral Section`,
  faq_section: `FAQ Section`,
  member_site_url: `Member Site URL`,
  question: `Question`,
  answer: `Answer`,
  add_faq: `Add FAQ`,
  rewards_issued: `Rewards Issued`,
  segments: `Segments`,
  exclude: `Exclude`,
  exclude_optional: `Exclude (optional)`,
  tag: `Tag`,
  visit: `Visit`,
  estimated_members: `Estimated Members`,
  actions: `Actions`,
  styles: `Styles`,
  theme_color: `Theme Color`,
  color_scheme: `Color Scheme`,
  cover_image: `Cover Image`,
  configure: `Configure`,
  configure_members_by_segment: `Configure - Members by Segment`,
  new_segment_input: `Members who signed up in the last <input/> days`,
  returning_segment_input: `Members who shopped at least <input1/> times in the last <input2/> days`,
  top_spending_segment_input: `Members who spent more than $ <input1/> on average in the last <input2/> days`,
  slipping_away_segment_input: `Members who didn't visit in the last <input/> days`,
  lost_segment_input: `Members who didn't visit in the last <input/> days`,
  account: `Account`,
  personal: `Personal`,
  brand: `Brand`,
  alerts: `Alerts`,
  integrations: `Integrations`,
  your_profile: `Your Profile`,
  first_name: `First Name`,
  last_name: `Last Name`,
  change_password: `Change Password`,
  change_password_desc: `Changing the password will logout all active sessions from other devices`,
  current_password: `Current Password`,
  new_password: `New Password`,
  verify_new_password: `Verify New Password`,
  password_mismatch: `Passwords does not match`,
  business_information: `Business Information`,
  business_name: `Business Name`,
  business_industry: `Business Industry`,
  address: `Address`,
  city: `City`,
  state_province: `State/Province`,
  country: `Country`,
  zip_postal_code: `Zip/Postal Code`,
  timezone: `Timezone`,
  brand_name: `Brand Name`,
  brand_color: `Brand Color`,
  logo: `Logo`,
  enable_fraud_alerts: `Enable Fraud Alerts`,
  points_fraud_alert_threshold: `Notify when a member earns more than the following amount of points`,
  points_fraud_alert_threshold_helper: `Note: Leave it empty if you don't want to get alerted.`,
  check_ins_fraud_alert_threshold: `Notify when a member checks-in more than the following number of times in a day`,
  check_ins_fraud_alert_threshold_helper: `Note: Leave it empty if you don't want to get alerted.`,
  clover: `Clover`,
  clover_integration_desc: `Connect your Clover account with RewardUp to integrate with the Clover Register or Clover Dining app.`,
  connected: `Connected`,
  disconnected: `Disconnected`,
  not_connected: `Not Connected`,
  devices: `Devices`,
  device_id: `Device ID`,
  device_name: `Device Name`,
  app_version: `App Version`,
  last_active: `Last Active`,
  activated_at: `Activated At`,
  create: `Create`,
  type: `Type`,
  campaign: `Campaign`,
  campaign_type: `Campaign Type`,
  last_updated: `Last Updated`,
  campaign_search: `Search by campaign`,
  push_content: `Push Content`,
  channel: `Channel`,
  announcement: `Announcement`,
  promotion: `Promotion`,
  voucher: `Voucher`,
  sent: `Sent`,
  delivered: `Delivered`,
  read: `Read`,
  action_url_clicks: `Action URL Clicks`,
  cta_clicks: `CTA Clicks`,
  failed: `Failed`,
  enable_wallet_pass_section: `Enable Wallet Pass Section`,
  clone: `Clone`,
  campaign_remove_desc: `Are you sure you want to remove the campaign <bold>{{title}}</bold>?`,
  billing: `Billing`,
  overview: `Overview`,
  overview_desc: `Snapshot of reward program activities`,
  invoices: `Invoices`,
  current_plan: `Current Plan`,
  payment_method: `Payment Method`,
  payment_method_required: `Please add a payment method`,
  upcoming_bill: `Upcoming Bill`,
  invoice_no: `Invoice No.`,
  invoice_date: `Invoice Date`,
  payment_status: `Payment Status`,
  view_invoice: `View Invoice`,
  monthly: `Monthly`,
  annually: `Annually`,
  billing_trial_alert: `You are currently in trial period and your default card on file will be automatically charged on <bold>{{date}}</bold>`,
  alert_clover_invoice: `Please visit Clover to view your invoices`,
  alert_clover_billing: `You are subscribed to our Clover <bold>{{plan}}</bold> plan. Please visit Clover to manage your subscription.`,
  change_plan: `Change Plan`,
  product_id_required: `Please select a plan`,
  subscription_plans: `Subscription Plans`,
  subscription_plans_desc: `Please select a subscription plan`,
  plan: `Plan`,
  plan_summary: `Plan Summary`,
  subtotal: `Subtotal`,
  taxes: `Taxes`,
  total: `Total`,
  card_format: `{{brand}} •••• {{last4}}`,
  renews_on_lc: `renews on`,
  plus_taxes: `+ taxes`,
  change_payment_method: `Change Payment Method`,
  payment_method_id_required: `Please enter the card details`,
  payment_method_id_select_required: `Please select a card`,
  payment_method_desc: `We use Stripe for securely processing our customer credit cards. Your monthly charges will be added to the primary credit card on file.`,
  text_messaging: `Text Messaging`,
  buy_credits: `Buy Credits`,
  sms_credits_format: `<bold>{{credits}}</bold> credits available`,
  sms_cost: `SMS Cost`,
  mms_cost: `MMS Cost`,
  per_segment_lc: `/ segment`,
  available_credits: `Available Credits`,
  credits_lc: `credits`,
  buy_sms_credits: `Buy SMS Credits`,
  purchase_summary: `Purchase Summary`,
  sms_bundles: `SMS Bundles`,
  sms_bundles_desc: `Please select a SMS bundle`,
  learn_more: `Learn more`,
  select_payment_method: `Please select a payment method`,
  add_payment_method: `Add Payment Method`,
  email_preview: `Email Preview`,
  send_preview_sms: `Send Preview SMS`,
  sms_usage: `SMS Usage`,
  mms_usage: `MMS Usage`,
  sms_sent: `SMS sent`,
  mms_sent: `MMS sent`,
  test_sms: `Test SMS`,
  test_email: `Test Email`,
  sms_credit_usage_format: `<bold>{{credit}} SMS Credit</bold> will be used`,
  analytics: `Analytics`,
  user: `User`,
  location: `Location`,
  location_name: `Location Name`,
  location_name_helper: `*Used for data filtering and reporting`,
  self: `Self`,
  select: `Select`,
  time: `Time`,
  campaign_scheduled_alert: `Campaign is scheduled to send at <bold>{{scheduledAt}}</bold>`,
  campaign_clone_desc: `<bold>{{title}}</bold> will be <bold>cloned</bold>. Do you want to proceed?`,
  campaign_draft_desc: `<bold>{{title}}</bold> will be moved to <bold>Draft</bold>. Do you want to proceed?`,
  move_to_draft: `Move to Draft`,
  points_issued_format: `{{points}} points issued`,
  members_count: `{{count}} members`,
  total_visit_format: `Total Visits: {{count}}`,
  total_spend_format: `Total Spend: {{amount}}`,
  average_spend_format: `Avg. Spend per visit: {{amount}}`,
  sender_information: `Sender Information`,
  sender_information_alert: `Your brand needs to activate a Toll-Free number and complete verification to start sending SMS/MMS to your subscribers.`,
  activate_toll_free_number: `Activate Toll-Free Number`,
  resubmit_toll_free_number_verification: `Resubmit Toll-Free Number Verification`,
  sender_information_alert_twilio: `SMS will be sent using RewardUp messaging service. If you want to verify your brand using a dedicated Toll Free Number, please contact `,
  help_port_toll_free_number: `Need to port a toll-free number to RewardUp?`,
  business_and_contact_information: `Business and Contact Information`,
  legal_entity_name: `Legal Entity Name`,
  legal_entity_name_helper: `Business legal name is the one that appears on the government and legal forms`,
  business_location: `Business Location`,
  messaging_use_case: `Messaging Use-Case`,
  estimated_monthly_volume: `Estimated Monthly Messages Volume`,
  sample_message_content: `Sample Message Content`,
  submit_verification: `Submit for Verification`,
  resubmit_verification: `Resubmit for Verification`,
  pending_review_alert: `You can send SMS only after your business is verified and approved by the mobile carriers`,
  toll_free_number: `Toll-Free Number`,
  daily_limit: `Daily Limit`,
  weekly_limit: `Weekly Limit`,
  monthly_limit: `Monthly Limit`,
  order_id: `Order ID`,
  order_receipt_format: `{{orderId}} - Order Receipt`,
  acceptance_required: `Please accept the terms of service`,
  agree_terms_service: `I agree to the Terms of Service`,
  agree_terms_service_helper: `I agree to the Terms of Service`,
  clover_devices: `Clover Devices`,
  rewards_check_in: `Rewards Check-In`,
  check_in_after_payment: `Enable Check-In after Payment`,
  idle_timeout: `Idle Timeout`,
  idle_timeout_desc: `Select the no. of seconds the check-in screen can be idle before auto-closing the app`,
  manual_points: `Manual Points`,
  allow_managers_manual_points: `Allow managers to manually add & remove points`,
  allow_employees_manual_points: `Allow employees to manually add & remove points`,
  sign_up_member: `Sign Up Member`,
  allow_managers_add_member: `Allow managers to sign up new member`,
  allow_employees_add_member: `Allow employees to sign up new member`,
  new_member_email_mandatory: `Email address is mandatory to sign up new member`,
  new_member_email_mandatory_helper: `(By default, RewardUp doesn't require email to sign up from the Clover app.)`,
  update_member_details: `Update Member Details`,
  allow_managers_edit_member: `Allow managers to update member details`,
  allow_employees_edit_member: `Allow employees to update member details`,
  store_credit: `Store Credit`,
  allow_managers_issue_store_credit: `Allow managers to issue store credit`,
  allow_employees_issue_store_credit: `Allow employees to issue store credit`,
  language_settings: `Language Settings`,
  french_language_support: `French language support`,
  new_members: `New Members`,
  check_ins: `Check-ins`,
  earned_points: `Earned Points`,
  available_points: `Available Points`,
  used_points: `Used Points`,
  total_points: `Total Points`,
  points_overview: `Points Overview`,
  rewards_overview: `Rewards Overview`,
  rewards_overview_desc: `Quick insight on the rewards redeemed by members`,
  this_week: `This Week`,
  last_week: `Last Week`,
  last_month: `Last Month`,
  last_year: `Last Year`,
  engagement_overview: `Engagement Overview`,
  top_employees: `Top Employees`,
  top_employees_desc: `Based on new member sign ups`,
  referrals_overview: `Referrals Overview`,
  pending: `Pending`,
  completed: `Completed`,
  total_referred: `Total Referred`,
  cover_section: `Cover Section`,
  caption: `Caption`,
  caption_placeholder: `Join the club`,
  sign_up_action_text: `Sign Up Action Text`,
  sign_in_text: `Sign In Text`,
  today: `Today`,
  yesterday: `Yesterday`,
  this_month: `This Month`,
  this_year: `This Year`,
  contact_information: `Contact Information`,
  contact_information_desc: `Will be publicly displayed on the member site and email`,
  social_media_information: `Social Media Information`,
  facebook: `Facebook`,
  facebook_optional: `Facebook (optional)`,
  google_business: `Google Business`,
  google_business_optional: `Google Business (optional)`,
  instagram: `Instagram`,
  instagram_optional: `Instagram (optional)`,
  trip_advisor: `Trip Advisor`,
  trip_advisor_optional: `Trip Advisor (optional)`,
  twitter: `Twitter`,
  twitter_optional: `Twitter (optional)`,
  yelp: `Twitter`,
  yelp_optional: `Yelp (optional)`,
  points_based_rewards: `Points based Rewards`,
  referral_rewards: `Referral Rewards`,
  vip_rewards: `VIP Rewards`,
  total_members: `Total Members`,
  employee: `Employee`,
  provisioning: `Provisioning`,
  light_mode: `Light Mode`,
  dark_mode: `Dark Mode`,
  reward_description_placeholder: `Eg: Spend 100 points and get $5 Off`,
  voucher_description_placeholder: `Eg: Get 10% OFF when you spend $50 or more. Valid until Dec 31st.`,
  retention_autopilot_birthday_description_placeholder: `Eg: Celebrate your birthday and get $5 Off`,
  retention_autopilot_second_visit_description_placeholder: `Eg: Get 10% Off on your second visit/purchase`,
  retention_autopilot_slipping_away_description_placeholder: `Eg: Get 10% Off on your next visit/purchase`,
  retention_autopilot_lost_description_placeholder: `Eg: Get 10% Off on your next visit/purchase`,
  retention_autopilot_top_spending_description_placeholder: `Eg: Get 10% Off on your next visit/purchase`,
  manage_notification: `Manage Notification`,
  toll_free_number_verification_notification: `Toll Free Number verification is required for all brands to send out SMS/MMS.`,
  verify_your_brand: `Verify your brand`,
  empty_rewards: `No rewards added`,
  empty_vouchers: `No vouchers added`,
  empty_available_rewards: `No rewards available`,
  voucher_selection_required: `Please select a voucher`,
  logged_in_as: `Logged in as`,
  support: `Support`,
  terms: `Terms`,
  privacy_policy: `Privacy Policy`,
  website_url: `Website URL`,
  website_url_optional: `Website URL (optional)`,
  website_url_helper: `*This link will also be used for the redeem button on the member site. You can enter your website, landing page link or google maps link.`,
  billing_alert: `Please select a plan and add a payment method to start using RewardUp. Your card will not be charged during the trial period.`,
  start_30day_free_trial: `Start your 30-day free trial`,
  billing_help: `Need help with your account setup and training?`,
  book_demo: `Book Demo`,
  setup_billing: `Setup Billing`,
  vip_program_disabled: `VIP program is not enabled.`,
  vip_program_location_unavailable: `VIP program is associated with the brand. Cannot be filtered based on a location`,
  referral_program_disabled: `Referral program is not enabled.`,
  referral_program_location_unavailable: `Referral program is associated with the brand. Cannot be filtered based on a location`,
  engagement_overview_location_unavailable: `Not applicable for the selected location`,
  gift_card_program: `Gift Card Program`,
  gift_cards: `Gift Cards`,
  gift_items: `Gift Items`,
  setup: `Setup`,
  gift_card_program_setup: `Gift Card Program Setup`,
  default_image: `Default Image`,
  gift_image: `Gift Image`,
  store_credit_image: `Store Credit Image`,
  terms_and_conditions: `Terms & Conditions`,
  gift_card_program_design: `Gift Card Program Design`,
  gift_card_program_design_desc: `Gift image, terms & conditions mentioned below are used in the gift emails, gift card site.`,
  enable_gift_card_program: `Enable Gift Card Program`,
  create_gift_item: `Create Gift Item`,
  modify_gift_item: `Modify Gift Item`,
  empty_gift_items: `No gift items added`,
  gift_item: `Gift Item`,
  gift_item_desc: `Manage the gift items for sale in-store and online.`,
  gift_item_name: `Item Name`,
  gift_item_name_placeholder: `$50 Gift Card`,
  gift_item_name_required: `Item Name is required`,
  gift_item_name_min: `Item Name must have at least 2 characters`,
  gift_item_price_required: `Purchase Cost is required`,
  gift_item_value_required: `Gift Value is required`,
  description_optional: `Description (optional)`,
  pricing: `Pricing`,
  fixed: `Fixed`,
  variable: `Variable`,
  purchase_cost: `Purchase Cost`,
  gift_value: `Gift Value`,
  draft: `Draft`,
  published: `Published`,
  available_purchase_in_store: `Available to Purchase In-Store`,
  available_purchase_online: `Available to Purchase Online`,
  gift_item_remove: `Remove - {{name}}`,
  gift_item_remove_desc: `Are you sure you want to remove the gift item?`,
  gift_card_transactions: `Gift Card Transactions`,
  gift_card_number: `Gift Card #`,
  transaction_type: `Transaction Type`,
  transaction_value: `Transaction Value`,
  card_balance: `Card Balance`,
  activation: `Activation`,
  reload: `Reload`,
  balance_check: `Balance Check`,
  redemption: `Redemption`,
  search: `Search`,
  gift_cards_search: `Card No. / Recipient`,
  gift_card_transactions_search: `Search by card number`,
  export: `Export`,
  generate_liability_report: `Generate Liability Report`,
  gift_cards_overview: `Gift Cards Overview`,
  gift_purchased_amount: `Gift Purchased Amount`,
  gift_redeemed_amount: `Gift Redeemed Amount`,
  gift_outstanding_balance: `Gift Outstanding Balance`,
  void: `Void`,
  view_receipt: `View Receipt`,
  payment_receipt_format: `{{paymentId}} - Payment Receipt`,
  purchased_on: `Purchased On`,
  activity_log: `Activity Log`,
  view: `View`,
  gift_card: `Gift Card`,
  sell_gift_card: `Sell Gift Card`,
  reload_gift_card: `Reload Gift Card`,
  reload_gift_card_desc: `Enter the amount to top-up the gift card balance`,
  check_card_balance: `Check Card Balance`,
  redeem_gift_card: `Redeem Gift Card`,
  redeem_gift_card_desc: `Enter the amount to redeem from the gift card balance`,
  gift_card_number_required: `Gift Card Number is required`,
  gift_card_balance: `Gift Card Balance`,
  gift_card_balance_desc: `Enter the gift card number to view the current balance`,
  available_balance: `Available Balance`,
  change_gift_card: `Change Gift Card`,
  search_gift_card: `Search Gift Card`,
  search_gift_card_desc: `Enter the gift card number to continue`,
  gift_card_details: `Gift Card Details`,
  gift_amount_redeem: `Gift Amount to Redeem`,
  gift_amount_redeem_required: `Gift Amount to Redeem is required`,
  gift_item_required: `Please select a Gift Item`,
  gift_item_selection_desc: `Choose a gift item`,
  gift_reload_value: `Gift Reload Value`,
  gift_reload_value_required: `Gift Reload Value is required`,
  new_balance: `New Balance`,
  recipient: `Recipient`,
  gift: `Gift`,
  recipient_name: `Recipient Name`,
  recipient_email: `Recipient Email`,
  gift_balance: `Gift Balance`,
  gift_card_transaction_void_format: `Void - {{cardNumber}} ({{action}})`,
  gift_card_transaction_void_desc: `Are you sure you want to void this gift card transaction?`,
  refund: `Refund`,
  void_activation: `Void of Activation`,
  void_reload: `Void of Reload`,
  void_redemption: `Void of Redemption`,
  any_amount: `any amount`,
  in_store_availability: `In-Store Availability`,
  online_purchase_availability: `Online Purchase Availability`,
  enabled: `Enabled`,
  disabled: `Disabled`,
  store_credit_card_balance: `Store Credit Balance`,
  store_credit_card_details: `Store Credit {{cardNumber}}`,
  store_credit_program: `Store Credit Program`,
  store_credit_cards: `Store Credit`,
  store_credit_card_transactions: `Store Credit Transactions`,
  store_credit_program_setup: `Store Credit Program Setup`,
  enable_store_credit_card_program: `Enable Store Credit Program`,
  store_credit_program_design: `Store Credit Program Design`,
  store_credit_program_design_desc: `Store credit image, terms & conditions mentioned below are used in the store credit emails.`,
  store_credit_card_number: `Store Credit #`,
  store_credit_card_number_required: `Store Credit Number is required`,
  redeem_store_credit_card: `Redeem Store Credit`,
  redeem_store_credit_card_desc: `Enter the amount to redeem from the store credit balance`,
  reload_store_credit_card: `Reload Store Credit`,
  reload_store_credit_card_desc: `Enter the reload amount for the store credit`,
  change_store_credit_card: `Change Store Credit`,
  store_credit_overview: `Store Credit Overview`,
  store_credit_issued_amount: `Store Credit Issued Amount`,
  store_credit_redeemed_amount: `Store Credit Redeemed Amount`,
  store_credit_outstanding_balance: `Store Credit Outstanding Balance`,
  store_credit_cards_search: `Card No. / Recipient / Notes`,
  store_credit_card_transactions_search: `Search by card number`,
  issued_on: `Issued On`,
  issue_store_credit_card: `Issue Store Credit`,
  store_credit_card_balance_desc: `Enter the store credit number to view the current balance`,
  search_store_credit_card: `Search Store Credit`,
  search_store_credit_card_desc: `Enter the store card number to continue`,
  store_credit_amount_redeem: `Store Credit Amount to Redeem`,
  store_credit_amount_redeem_required: `Store Credit Amount to Redeem is required`,
  store_credit_amount_reload: `Store Credit Amount to Reload`,
  store_credit_amount_reload_required: `Store Credit Amount to Reload is required`,
  store_credit_card_issue: `Store Credit Details`,
  store_credit_card_issue_desc: `You can issue store credit balance with digital or plastic cards.`,
  store_credit_card_value: `Store Credit Value`,
  store_credit_card_value_required: `Store Credit Value is required`,
  plastic_card: `Plastic Card`,
  digital_card: `Digital Card`,
  recipient_name_optional: `Recipient Name (optional)`,
  recipient_email_required: `Recipient Email is required`,
  valid_from_optional: `Valid From (optional)`,
  valid_through_optional: `Valid Through (optional)`,
  internal_notes: `Internal Notes`,
  internal_notes_optional: `Internal Notes (optional)`,
  internal_notes_helper: `E.g., Employee Number, Cause/Event Name`,
  reward_card: `Reward Card`,
  store_credit_card: `Store Credit`,
  store_credit_balance: `Store Credit Balance`,
  store_credit_card_transaction_void_format: `Void - {{cardNumber}} ({{action}})`,
  store_credit_card_transaction_void_desc: `Are you sure you want to void this store credit transaction?`,
  receiver: `Receiver`,
  apple_wallet: `Apple Wallet`,
  eligible_locations: `Eligible Locations`,
  eligible_locations_desc: `Select the locations where the reward can be redeemed`,
  locations: `Locations`,
  voucher_overview: `Voucher Overview`,
  voucher_name_required: `Voucher name is required`,
  voucher_description_required: `Voucher description is required`,
  voucher_details: `Voucher Details`,
  voucher_name: `Voucher Name`,
  voucher_description: `Voucher Description`,
  discount_type: `Discount Type`,
  email_notification: `Email Notification`,
  voucher_email_notification_desc: `Manage the email notification for the voucher`,
  preview_email: `Preview Email`,
  send_test_email: `Send Test Email`,
  sms_notification: `SMS Notification`,
  voucher_sms_notification_desc: `Manage the sms notification for the voucher`,
  sms_notification_credit_alert: `SMS credits are required to send SMS notifications. You currently have <bold>{{smsCredits}}</bold> sms credits.`,
  edit_sms: `Edit SMS`,
  preview_sms: `Preview SMS`,
  send_test_sms: `Send Test SMS`,
  push_notification: `Push Notification`,
  voucher_push_notification_desc: `Manage the push notification for the voucher`,
  edit_push: `Edit Push`,
  preview_push: `Preview Push`,
  email_subscribers: `Email Subscribers`,
  sms_subscribers: `SMS Subscribers`,
  push_subscribers: `Push Subscribers`,
  available_sms_credits: `Available SMS Credits`,
  notification_delivery_time: `Notification Delivery Time`,
  notification_delivery_time_alert_voucher: `Notifications will be sent out to eligible members 1 day before the effective date, strictly within the business hours of 8 am to 8 pm in the business time zone.  In instances where the effective date is not specified, notifications will be sent out during the same timeframe on the same day or on the subsequent business day.`,
  notification_delivery_time_alert_retention_autopilot: `Notifications will be sent out to eligible members the next day, strictly within the business hours of 8 am to 8 pm in the business time zone.`,
  notification_delivery_time_alert_retention_autopilot_birthday: `Notifications will be sent out to eligible members 3 days before their birthday or birthday month, strictly within the business hours of 8 am to 8 pm in the business time zone.`,
  asap: `ASAP`,
  buy_more_credits: `Buy More Credits`,
  issued: `Issued`,
  edit_preview_email: `Edit & Preview Email`,
  edit_preview_sms: `Edit & Preview SMS`,
  edit_preview_push: `Edit & Preview Push`,
  voucher_setup_notification: `Setup Notifications`,
  voucher_setup_notification_desc: `Would you like to setup notifications for all the eligible members?`,
  retention_autopilot_setup_notification: `Setup Notifications`,
  retention_autopilot_setup_notification_desc: `Would you like to setup notifications for all the eligible members?`,
  expired_on: `Expired On`,
  redeemed_on: `Redeemed On`,
  retention_autopilot_overview: `Overview`,
  notification_enabled: `Notification Enabled`,
  notification_disabled: `Notification Disabled`,
  email_sent: `Email Sent`,
  push_sent: `Push Sent`,
  yearly: `Yearly`,
  gift_site_url: `Gift Site URL`,
  resend_email: `Resend Email`,
  resend_email_format: `Resend Email - {{cardNumber}}`,
  resend_store_credit_email_desc: `Are you sure you want to re-send the store credit to the recipient email `,
  change_recipient_email_format: `Change Recipient Email - {{cardNumber}}`,
  oauth_consent: `OAuth Consent`,
  account_access: `Account Access`,
  account_access_desc: `<bold>{{name}}</bold> wants to connect to your RewardUp account.`,
  approve: `Approve`,
  deny: `Deny`,
  please_wait: `Please wait.. `,
  valid_from: `Valid From`,
  valid_through: `Valid Through`,
  button_text_color: `Text Color`,
  button_color: `Button Color`,
  widget_text: `Widget Text`,
  widget_button_styles: `Widget Button Styles`,
  button_text_color_required: `Button text color is required`,
  button_color_required: `Button color is required`,
  invalid_color_hexcode: `Invalid hexcode`,
  text_placement: `Text Placement`,
  copy_code: `Copy Code`,
  widget_placement: `Widget Placement`,
  cover_image_helper_text: `Upload an image representing your brand. Recommended image size is 2000*160.`,
  widget_icon: `Widget Icon`,
  widget_icon_helpertext: `Choose an icon or upload your own. Recommended icon size is 512*512.`,
  text_and_icon_color: `Text & Icon Color`,
  member_site_setup: `Member Site Setup`,
  website_widget: `Website Widget`,
  member_website_url_desc: `Add the member site to your business website using a CTA button or a menu link.`,
  qr_code_desc: `Download the QR code for the member site. Add it to your marketing materials or to a tent card on the counter or table.`,
  qr_code: `QR Code`,
  embed_widget: `Embed Widget Code`,
  embed_widget_header: `Place the code before the closing body tag on any page on your website.`,
  top_left: `Top left`,
  top_right: `Top right`,
  bottom_left: `Bottom left`,
  bottom_right: `Bottom right`,
  program_stats: `Program Stats`,
  signup_source: `Sign Up Source`,
  user_activity: `User Activity`,
  communication: `Communication`,
  communications_activity: `Communications Activity`,
  manual_adjustments_activity: `Manual Adjustments Activity`,
  top_spenders: `Top Spenders`,
  reports: `Reports`,
  store_locations: `Store Location`,
  redemptions: `Redemptions`,
  new_signups: `New Sign Ups`,
  points_activity: `Points Activity`,
  frequent_location: `Frequent Location`,
  frequent_locations: `Frequent Locations`,
  search_store_location: `Search by location`,
  search_store_location_or_user: `Search by location or user`,
  search_store_location_or_user_or_member_name: `Search by location or user or member name`,
  search_store_location_or_member_name: `Search by location or member name`,
  search_store_member_or_phone_number: `Search by member or phone number`,
  frequent_visitors: `Frequent Visitors`,
  frequent_visitors_desc: `Based on the most number of check-ins`,
  frequent_location_format: `Frequent Location: {{name}}`,
  manage_users: `Manage Users `,
  role: `Role`,
  last_activity: `Last Activity`,
  member_user_search: `Search by email or name`,
  invite_team: `Invite Team`,
  send_invite: `Send Invite`,
  send_invites: `Send Invites {{count}}`,
  assign_locations: `Assign Locations`,
  alert_non_trackable_activity: `This activity cannot be tracked and points will be issued when the member clicks on the link from the member site / email / sms.`,

  max_char: `Maximum {{count}} characters allowed`,
  min_char: `Minimum {{count}} characters required`,
  max_points: `Maximum {{count}} points allowed`,
  min_point: `Minimum {{count}} point required`,
  max_percentage: `Maximum {{count}} percentage allowed`,
  min_percentage: `Minimum {{count}} percentage required`,
  max_amount: `Maximum $ {{count}} allowed`,
  min_amount: `Minimum $ {{count}} is required`,
  min_location: `Minimum {{count}} location required`,
  max_location: `Maximum {{count}} location`,
  max_collection: `Maximum {{count}} collections are allowed`,
  min_days: `Minimum {{count}} day required`,
  max_days: `Maximum {{count}} days required`,
  max_items: `Maximum {{count}} items`,
  decimal_value_not_allowed: `Decimal value not allowed`,
  no_special_char: `No special characters allowed`,
  no_special_char_except: `No special characters allowed except $ % & { } ! _`,
  positive_value_required: `Positive value is required`,
  invalid_url: `Invalid url`,
  start_date_required: `Start date required`,
  end_date_required: `End date required`,
  eligible_days_required: `Eligible days is required`,

  signup_program_name: `Join the Club`,
  signup_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  place_order_program_name: `Place Order`,
  place_order_program_description: `Earn 1 point on every $1 spent`,

  purchase_item_program_name: `Purchase Item`,
  purchase_item_program_description: `Earn {{earning_points}} {{points_program_name}} for every purchase of {{item}}`,

  write_review_program_name: `Write Review`,
  write_review_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  birthday_club_program_name: `Birthday Club`,
  birthday_club_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  sms_subscription_program_name: `Subscribe to SMS`,
  sms_subscription_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  email_subscription_program_name: `Subscribe to Email`,
  email_subscription_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  wallet_pass_program_name: `Add to Mobile Wallet`,
  wallet_pass_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  facebook_like_program_name: `Like on Facebook`,
  facebook_like_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  instagram_follow_program_name: `Follow on Instagram`,
  instagram_follow_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  x_follow_program_name: `Follow on X`,
  x_follow_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  tiktok_like_program_name: `Like on TikTok`,
  tiktok_like_program_description: `Earn {{earning_points}} {{points_program_name}}`,

  /**
   * Program Details
   */
  earning_type_required: `Earning type is required`,
  spending_unit_required: `Spending unit is required`,
  minimum_purchase_amount_required: `Minimum purchase amount required`,
  earning_points_must_be_a_number: `Earning points must be a number`,
  excluded_inventory_collections_required: `Atleast 1 inventory collection is required`,
  excluded_inventory_items_required: `Atleast 1 inventory item is required`,

  inventory_collections_required: `Atleast 1 inventory collection is required`,
  inventory_items_required: `Atleast 1 inventory item is required`,
  icon_file_size_limit: `File size must be less than {{count}}KB`,
  unsupported_file_format: `Unsupported file format`,
  icon_required: `Icon is required`,

  inventory_collections_only_show_show_clover: `Note: Only the first {{count}} items from a collection can be displayed on the Clover app.`,
  midnight: `Midnight`,

  restricted_words_alert_message: `Please remove the restricted words to continue. `,
  view_restricted_word_list: `View list of restricted words.`,
  restricted_words: `Restricted Words`,
  min_required: `min {{count}} required`,
  days_value_required: `Days value must be between {{start}} and {{end}}`,
  spent_dollar_value_required: `Spent dollar value must be between $ {{start}} and $ {{end}}`,
  visit_value_required: `Visit value must be between {{start}} and {{end}}`,
  eligible_member_segments_required: `Eligible Member segments is required`,
  excluded_recipients: `Excluded Recipients`,
  sent_on: `Sent on`,
  scheduled_send_on: `Scheduled to send on`,
  sms_credits_used: `SMS credits used`,
  usage_count_required: `Usage count is required`,
  discount_percentage_required: `Discount percentage is required`,
  expires_in_required: `Expires In is required`,
  reward_expiry_required: `Reward Expiry is required`,
  discount_amount_required: `Discount Amount is required`,

  member_tag_helper: `Press Enter to add a tag`,
  end_date_or_future_date_required: `End date must be current date or a future date`,
  modify_sign_up_reward: `Modify Sign Up Reward`,
  signup_reward_details: `Sign Up Reward Details`,
  signup_reward_desc: `Give your new members a sign up reward`,
  alert_bonus_earning_points: `Bonus points are awarded on top of the base points rule. For instance, to implement a 2X point rule: If your base points rule is $1 = 1 point, then the bonus earning points must be 1, with $1 as the spending unit.`,
  alert_bonus_points_quantity: `<bold>Fixed/Variable Price Item</bold><br/>Points will be issued to each quantity for fixed/variable price items.<br/>For example, 1 x Coffee = 10 points and 2 x Coffee = 20 points.<br/><br/><bold>Unit Price Item</bold><br/>Points will be issued per unit for unit based price items.<br/>For example, 1 Oz x Frozen Yogurt = 10 points and 2 Oz x Frozen Yogurt = 20 points.`,
  alert_purchase_item_quantity: `Points will not be issued for each quantity. For example, 1 x Coffee = 10 points and 2 x Coffee = 10 points. If you like to issue points for each quantity, create a Bonus point rule.`,
  message_breakdown: `Message Breakdown`,
  view_message_breakdown: `View Message Breakdown`,
  sms_special_char_alert_message: ` Tip: Remove emojis or special characters like {{specialChars}} to reduce SMS
  credits per message`,
  all_members: `All Members`,
  leave_voucher_tab: 'Leave voucher {{name}} tab?',
  tab_leave_desc: `You have unsaved changes that will be lost if you leave this tab`,
  continue_editing: `Continue Editing`,
  leave: `Leave`,
  member_segments: `Member Segments`,
  visit_based: `Visit Based`,
  activated_on: `Activated On`,
  store_credit_setup: `Store Credit Setup`,
  gift_card_setup: `Gift Card setup`,
  gift_card_info: `Gift Card Info`,
  customer_info: `Customer Info`,
  initial_value: `Initial Value`,
  activated_date: `Activated Date`,
  last_activity_date: `Last Activity Date`,
  email_gift_card: `Email Gift Card`,
  email_gift_card_desc: `Are you sure you want to send the gift card to the recipient email`,
  perk_title_required: `Perk description cannot be empty`,
  activate_tfn_message: `Activate Toll Free Number to send out SMS/MMS.`,
  reconciliation_report: `Reconciliation Report`,
  gift_card_reconciliation_report: `Gift Card Reconciliation Report`,
  gift_cards_sold: `Gift Cards Sold`,
  gift_cards_sold_value: `Gift Cards Sold Value`,
  gift_cards_redeemed: `Gift Cards Redeemed`,
  gift_cards_redeemed_value: `Gift Cards Redeemed Value`,
  total_due: `Total Due`,
  active_gift_card: `Active Gift Cards`,
  active_store_credit: `Active Store Credit`,
  last_used: `Last Used`,
  resend: `Resend`,
  store_card_info: `Store Credit Info`,
  confirm_logout_desc: `Are you sure you want to logout?`,
  email_store_credit_card: `Email Store Credit`,
  recipients_required: `Recipients is required`,
  widget_text_required: `Widget text is required`,
  amount_less_than_available_balance: `Amount must be less than or equal to available balance`,
  business_name_required: `Business Name is required`,
  business_industry_required: `Business Industry is required`,
  location_name_required: `Location Name is required`,
  address_required: `Address is required`,
  city_required: `City is required`,
  state_province_required: `State/Province is required`,
  country_required: `Country is required`,
  zip_postal_code_required: `Zip/Postal Code is required`,
  timezone_required: `Timezone is required`,
  last_updated_date_format: `Last updated: {{date}}`,
  sms_campaign_mms_alert_message: `Picture messages cost 3 times more. Consider sending a text message to reduce your SMS costs.`,
  sign_up_source: `Sign Up Source`,
  review_request_notification: `Review Request Notification`,
  req_review_after_days_purchase: `Request review after {{day}} purchase`,
  request_review_email_notification_desc: `Manage the email notification for the <bold>Review</bold>`,
  review_request_sms_notification_desc: `Manage the sms notification for the <bold>Review</bold>`,
  referral_program_notification: `Referral Program Notification`,
  referral_program_sms_notification_desc: `Manage the sms notification for the <bold>Referral Program</bold>`,
  referral_program_email_notification_desc: `Manage the email notification for the <bold>Referral Program</bold>`,
  send_referral_link_after_purchase: `Send referral link after {{purchase}} purchase`,
  getting_started: `Getting Started`,
  getting_started_progress: `Getting Started ({{completedCount}}/{{totalCount}})`,
  welcome_to_rewardUp: `Welcome to RewardUp`,
  getting_started_steps_count_one: `You are 1 step away from launching your rewards program`,
  getting_started_steps_count_other: `You are {{count}} steps away from launching your rewards program`,
  getting_started_steps_heading_description: `We recommend completing all the steps below to launch a fully branded rewards program.`,
  setup_your_brand: `Setup Your Brand`,
  setup_brand_description: `Customize your brand theme color and upload your logo`,
  setup_point_rules: `Setup Point Rules`,
  setup_point_rules_description: `Customize how your loyalty members will earn points`,
  setup_reward_levels: `Setup Reward Levels`,
  setup_reward_levels_description: `Create your point based reward levels`,
  setup_apple_wallet_pass: `Setup Apple Wallet Pass`,
  setup_apple_wallet_pass_description: `Customize your digitial reward card`,
  setup_member_page: `Setup Rewards Program Member Page`,
  setup_member_page_description: `Customize your rewards member page`,
  setup_activate_toll_tree_number: `Activate Toll Free Number`,
  setup_activate_toll_tree_number_description: `Register your brand with mobile carriers to send SMS to your members.`,
  setup_activate_retention_autopilot: `Activate Retention Autopilot`,
  setup_activate_retention_autopilot_description: `Customize your customer retention discounts`,
  setup_manage_notifications: `Manage Notifications`,
  setup_manage_notifications_description: `Customize your program notifications`,
  setup_activate_clover_app: `Activate Clover App`,
  setup_activate_clover_app_description: `Open RewardUp app on the Clover device and tap on "Get Started". You must repeat this step on all the Clover devices.`,
  setup_enroll_first_member_on_clover: `Enroll First Member on Clover`,
  setup_enroll_first_member_on_clover_description: `Sign Up a new member to your rewards program from Clover`,

  clover_sms_notification_credit_alert: `SMS usage will be billed to your Clover billing at 3 cents per SMS segment (160 characters). If you would like to save on SMS costs, please email support@rewardup.com or call (866)510-6677.`,
  oops_error_occured: 'Oops! An Error Occured',
  retry: `Retry`,
  buy_sms_credit_message_one: `You have <bold>1</bold> credit available. Please buy more credits to send SMS.`,
  buy_sms_credit_message_other: `You have <bold>{{count}}</bold> credits available. Please buy more credits to send SMS.`,
  buy_sms_credit: `Buy SMS Credit`,
  account_information: `Account Information`,
  business_address: `Business Address`,
  create_account: `Create Account`,
  business_phone_number: `Business Phone Number`,
  user_information: 'User Information',
  business_phone_number_required: `Please provide a valid phone number`,
  business_address_required: `Business Address is required`,
  first_name_required: `First Name is required`,
  last_name_required: `Last Name is required`,
  mobile_number_required: `Please provide a valid mobile number`,
  best_phone_number_helper: 'Please provide the best contact number for support',
  go_to_login: `Go to Login`,
  an_error_occurred: `An Error Occurred`,
  new_to_rewardup: `New to RewardUp ?`,
  create_location: `Create Location`,
  add_location: `Add Location`,
  add_new_location: `Add New Location`,
  street_1: `Street 1`,
  street_2: `Street 2`,
  square: `Square`,
  clover_member_id_connect_error: `The Clover account you were trying to connect is already associated with another business.`,
  connecting_to_clover: `Connecting to clover`,
  card_expiry: '{{expiry_month}}/{{expiry_year}}',
  billing_email: `Billing Email`,
  payment_method_card_format: `Payment method : {{brand}} •••• {{last4}}`,
  manage: `Manage`,
  location_information: `Location Information`,
  edit_location: `Edit Location`,
  yes: `Yes`,
  no: `No`,
  main_location_billing_manage: `Billing for this location is managed by the main location.`,
  remove_location_title: `Do you want to remove this location?`,
  remove_location_warn: `Removing this location will permanently remove this location from your RewardUp account  and remove any subscriptions related to this location.`,
  remove_location_uninstall_app: `You will have to uninstall the RewardUp app from the Clover app market to remove the RewardUp app from the Clover account.`,
  remove_location: `Remove Location`,
  disconnect_clover: `Disconnect Clover`,
  disconnect_clover_content: `Are you sure you want to disconnect the clover account?`,
  disconnect_clover_note: `Note: You will have to uninstall the RewardUp app from the Clover app market to remove the RewardUp app from the Clover account.`,
  go_to_home: `Go to Home`,
  clover_connected_success: `Clover connected successfully`,
  disassociate_clover: `Disassociate Clover`,
  disassociate_clover_content: `Are you sure you want to disassociate the clover account?`,
  already_have_an_account: `Already have an account?`,
  sign_in: 'Sign In',
  mobile_number: 'Mobile Number',
  website_protocol_required: `Url must start with "http://" or "https://"`,
  cover_caption_required: `Cover Caption is required`,
  cover_title_required: `Cover Title is required`,
  welcome_title_required: `Welcome Title is required`,
  inventory: `Inventory`,
  sync_inventory_collections_items_desc: `Sync the clover inventory collections and items`,
  sync: `Sync`,
  last_synced_at: `Last synced at {{date}}`,
  complete_step_from_clover: `Please complete this step from the clover device.`,
  auto_recharge_settings: `Auto Recharge Settings`,
  auto_recharge: `Auto Recharge`,
  auto_recharge_desc: `Configure auto recharge for SMS credits`,
  top_up_sms_bundle: `Top Up SMS bundle`,
  balance_falls_below: `When Balance Falls Below`,
  auto_recharge_credit_alert: `Enabling auto recharge will add more SMS credits to your account when the balance falls below a threshold.`,
  balance_falls_bellow_helper_text: `Min. credits is 100 and Max. credit is 1000`,
  auto_recharge_sms_credits: `Auto recharge SMS credits`,
  credits_required: `Credits is required`,
  min_credits: `Min {{credit}} credit is required `,
  max_credits: `Max {{credit}} credit allowed`,
  signup_agree_terms_condition: `I agree to receive account notifications and support responses via SMS/Email from RewardUp.`,
  search_by_card_helper_text: `Search with full card number or last 4 digits`,
  leave_retention_autopilot_tab: `Leave retention autopilot {{name}} tab?`,
  user_invite: `User Invite`,
  accept_invite: `Accept Invite`,
  update_user: `Update User`,
  admin: 'Admin',
  resend_invite: `Resend Invite`,
  accept_invite_desc: `Create your user to accept invitation`,
  already_registered_login: `Already Registered ? Login.`,
  min_email_allowed: `Min 1 email is required`,
  max_email_allowed: `Max {{count}} emails allowed `,
  users_access_role_desc: `Each user will have access to your store dashboard based on the role and location assigned`,
  emails_helper_test: `Comma separate to add multiple emails at once`,
  user_location_helper_text: `By default, the primary location will be assigned to the user.`,
  add_user: `Add User`,
  resend_email_invite: `Resend Email Invite`,
  resend_email_invite_desc: `Are you sure you want to re-send the invite email to the user email`,
  invite_sent: `Invite Sent`,
  invalid_invitation_token: 'Invalid Invitation Token',
  marketing: `Marketing`,
  manager: `Manager`,
  invite_expired: `Invite Expired`,
  location_stipe_billing_alert: `You are currently using Clover for billing. Please migrate to Stripe to access this feature.`,
  invitation_token_expired: `The invitation token has expired.`,
  role_required: `Role is required`,
  user_email_exits: `User already exist with this email address `,
  duplicate_email_exits: `Remove duplicate email`,
  average_spend: 'Average Spend',
  referral_program_setup: `Referral Program Setup`,
  review_overview: `Review Overview`,
  review_rule_setup: `Review Rule Setup`,
  review_notifications: `Review Notifications`,
  landline_invalid_phone_number: `Landline / Invalid Number`,
  invalid_email: `Invalid Email`,
  merge_member: `Merge Member`,
  remove_member: `Remove Member`,
  member_remove: `{{name}} - Remove`,
  member_remove_desc: `Are you sure you want to remove this member profile? This action cannot be undone.`,
  member_remove_confirmation: `Type REMOVE to confirm`,
  invalid_member_remove_confirmation: `Please enter REMOVE to confirm`,
  member_removed: `Member Removed`,
  member_member_desc: `Search & select the member you want to merge <bold>{{account_name}}</bold> profile`,
  member_member_alert_desc: `Are you sure you want to merge <bold>{{account_name}}</bold> profile with <bold>{{merge_account}}</bold>?`,
  action_cannot_undone: `This action cannot be undone.`,
  merge_member_profile: `Merge Member Profile`,
  merge_member_search_helper: `Search with name, email, phone number or last 4 digits of rewards card number`,
  gift_program: `Gift Program`,
  settings_personal: `Personal Settings`,
  settings_business: `Business Settings`,
  settings_integrations: `Integration Settings`,
  permissions: `Permissions`,
  merge_account_required: `Please select a valid member account to merge`,
  merge_member_alert: `Only points balance and activity history will be merged. Member Name, Phone Number, Email Address & Birthday will not be merged.`,
  card: `Card`,
  no_member_found: `No member found`,
  member_point_one: `point`,
  member_point_other: `points`,
  leave_review_tab: 'Leave review {{name}} tab?',
  leave_referral_notification_tab: 'Leave referral notification tab?',
  advanced_points_rules: `Advanced Points Rules`,
  no_advanced_rules: `No advanced points rules added`,
  advanced_points_rules_desc: `Setup advanced points rules to your rewards program. Eg. Double points, Category or Item based points`,
  advanced_points_rules_form_desc: `Motivate your members to participate in your reward program by offering instant bonus points. Setup advanced points rules to your rewards program. Eg. Double points, Category or Item based points`,
  modify_advanced_point_rule: `Modify Advanced Point Rule`,
  create_advanced_point_rule: `Create Advanced Point Rule`,
  additional_settings: `Additional Settings`,
  disable_points_round_off: `Don't round-off points`,
  disable_points_round_off_helper: `(By default, RewardUp rounds off to the nearest dollar value to calculate points. Only applicable for Clover Register or Clover Dining.)`,
  calculate_points_on_total: `Calculate points on order total`,
  calculate_points_on_total_helper: `(By default, RewardUp calculates points on the subtotal. Only applicable for Clover Register or Clover Dining.)`,
  allow_multiple_reward_redemption: `Allow to redeem multiple rewards on the same purchase`,
  allow_multiple_reward_redemption_helper: `(By default, RewardUp allows to redeem only one reward per order. Only applicable for Clover Register or Clover Dining.)`,
  print_reward_redemption_receipt: `Print reward redemption receipt`,
  apply_discount_manually_after_redeem: `Apply discount manually after redeeming a reward`,
  apply_discount_manually_after_redeem_helper: `(By default, RewardUp applies the discount targeted for a reward. Only applicable for Clover Register or Clover Dining.)`,
  disable_member_listing: `Don’t display members on the members' page on the Clover app`,
  disable_member_listing_helper: `(By default, RewardUp displays all the members on the Member page in the app.)`,
  disassociate_clover_account: `Disassociate Clover Account`,
  number_of_cards: `Number of Cards`,
  duplicate_store_credit_card_exits: `Remove duplicate store credit`,
  number_of_cards_required: `Number of cards is required`,
  business_name_restricted_word_helper: `Your brand name has a restricted word. Please update it.`,
  add_more_store_credit_card: `Add {{count}} more store credit.`,
  popular: `Popular`,
  this_include: 'This includes',
  subscribe: `Subscribe`,
  choose_subscription: `Choose Subscription`,
  change_subscription: `Change Subscription`,
  update: `Update`,
  change_subscription_plan_upgrading_alert_desc: `You are switching from {{currentPlan}} to {{selectedPlan}} plan. You will have access to all the {{selectedPlan}} plan features as soon as you update to the {{selectedPlan}} plan.`,
  change_subscription_plan_downgrading_alert_desc: `You are switching from {{currentPlan}} to {{selectedPlan}} plan. You will loose access to all the {{currentPlan}} plan features as soon as you update to the {{selectedPlan}} plan.`,
  subscription_charges_prorated_desc: `Note: Subscription charges are prorated and added to the next invoice.`,
  included_taxes: `(incl. taxes)`,
  legacy: `Legacy`,
  engage_premium: `Engage Premium`,
  gift_card_scheduled_alert: `Gift Card is scheduled to send at <bold>{{deliveryAt}}</bold>`,
  upload_image_helper: `Upload an image representing your brand. Recommended image size is {{width}}x{{height}} px.`,
  card_value: `Card Value`,
  card_value_required: `Card Value is required`,
  store_credit_card_required: `Store Credit is required`,
  bulk_issue_store_credit_card_one: `Issue Store Credit`,
  bulk_issue_store_credit_card_other: `Issue Store Credit ({{count}})`,
  store_credit_card_already_exist: `Store Credit Number already exist`,
  change_recipient_name_format: `Change Recipient Name - {{cardNumber}}`,

  /**
   * Shopify
   */
  shopify: `Shopify`,
  shopify_domain: `Shopify Domain`,
  enter_shopify_domain: `Enter Shopify Domain`,
  shopify_subdomain: `.myshopify.com`,
  shopify_desc: `Connect your Shopify account with RewardUp`,
  search_helper_text: `Please enter min 3 char to begin your search`,
  add_location_item: `Add Location Item`,
  add_shopify_item: `Add Shopify Item`,
  add_shopify_collection: `Add Shopify Collection`,
  discount_percentage_shopify_alert_message: `Note: Discount percentage is not supported for Shopify items. To offer a free product on Shopify, choose discount amount.`,
  manage_item_shopify_alert_message: `Note: Selection is limited to either the entire item or a specific variant.`,
  restrict_collection_based_discount_shopify_message: `Note: Collection based discounts are not supported for Shopify.`,
  print_store_credit_card: `Print Store Credit`,
  store_credit_card_bulk_issue_progress: `{{current}} / {{total}} store credit issued`,
  credit_card_number: `Card #`,
  check_credit_balance: `Check Credit Balance`,
  balance: `Balance`,
  friend_reward_alert_desc: `Friend Reward will override the Sign Up Reward from the Reward Levels section`,
  disconnect_shopify: `Disconnect Shopify`,
  disconnect_shopify_content: `Are you sure you want to disconnect the shopify account?`,
  normal_website: 'Normal Website',
  shopify_connect_failed: `Something went wrong in connecting shopify`,
  website_type: 'Website Type',
  shopify_widget: 'Shopify Widget',
  shopify_widget_desc: 'Add RewardUp widget to your shopify website',
  punch_cards_program: `Punch Card Program`,
  modify_punch_cards: `Modify Punch Card`,
  create_punch_cards: `Create Punch Card`,
  punch_card_detail: 'Punch Card Details',
  punch_required_earn_reward: `Stamps required to earn a reward`,
  eligible_items_heading: `Eligible items to earn stamps`,
  eligible_items: `Eligible Items`,
  eligible_items_helper_text: 'Select the items to earn a stamp/punch',
  select_reward_punching_heading: `Select the reward for completing the punch card`,
  modify_punch_card: `Modify Punch Card`,
  punch_count_required: `Punch Count is required`,
  punch_card_icon_helper_text: `Choose an icon or upload your own. Recommended icon size is 512*512`,
  stamp_icon: `Stamp Icon`,
  stamps: `Stamps`,
  punch_cards: `Punch Cards`,
  punch_card: `Punch Card`,
  punch_card_search: `Search by action`,
  punch_card_section: `Punch Card Section`,
  remove_stamps_placeholder: `eg., 1`,
  remove_stamps: `Remove Stamps`,
  add_stamps: `Add Stamps`,
  stamps_lc: `stamps`,
  add_stamps_placeholder: `eg., 1`,
  new_stamps_balance: `New Stamps Balance`,
  add_stamps_description_placeholder: `eg., Special Gift`,
  remove_stamps_description_placeholder: `eg., Order Refunded`,
  remove_stamps_reason_placeholder: `eg., Stamps removed for cancelling the order`,
  stamps_reason_placeholder: `For internal use. Not visible to the member.`,
  add_stamps_reason_placeholder: `eg., Extra stamps to make customer smile`,
  min_stamp: `Min Stamp`,
  member_stamps: `Member Stamps`,
  min_max_stamp: `Min {{min}} Max {{max}}`,
  advanced_reward_rules: `Advanced Reward Rules`,
  limit_stamp_order: `Limit to one stamp per order`,
  brand_logo: `Brand Logo`,
  manual_stamps: `Manual Stamps`,
  allow_managers_manual_stamps: `Allow managers to manually add & remove stamps`,
  allow_employees_manual_stamps: `Allow employees to manually add & remove stamps`,
  eligible_members_segment_reward_desc: `Members who meet all of these segments/filters will be eligible for this reward. Disable this option if you want all the members to be eligible for this reward.`,
  eligible_members_segment_voucher_desc: `Members who meet all of these segments/filters will be eligible for this voucher. Disable this option if you want all the members to be eligible for this voucher.`,
  punch_card_program: `Punch Card Program`,
  earn_stamps: `Earn Stamps`,
  earn_punch_card_reward: `Earn Punch Card Reward`,
  points_program: `Points Program`,
  wallet_pass_image: `Wallet Pass Image`,
};

export default en;
