export const APP_ID = 'lsxcdktm'

const canUseIntercom = window && window.Intercom

const bootIntercom = (user = {}) => {
  if (canUseIntercom) {
    window.Intercom('boot', {
      app_id: APP_ID,
      ...user,
    })
  }
}

export const shutdownIntercom = () => {
  if (canUseIntercom) {
    window.Intercom('shutdown')
  }
}

export const updateIntercom = () => {
  if (canUseIntercom) {
    window.Intercom('update')
  }
}

export default bootIntercom
