import PropTypes from 'prop-types';
// @mui
import { Box, Container, Link, Stack, Typography } from '@mui/material';
// hooks
import { useLocales } from '../../locales';
// components
import Logo from '../../components/logo';
//
import { StyledContent, StyledRoot, StyledSection, StyledSectionBg } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default function LoginLayout({ children }) {
  const { translate } = useLocales();

  return (
    <StyledRoot>
      <StyledSection>
        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>

        <Box
          component="footer"
          sx={{
            py: 1,
            textAlign: 'center',
          }}
        >
          <Container>
            <Logo sx={{ mt: 5, mb: 1, mx: 'auto' }} mode="type" />

            <Stack sx={{ my: 1 }} direction="row" justifyContent="center" spacing={1}>
              <Link
                href="https://rewardup.com/terms"
                color="inherit"
                variant="body2"
                target="_blank"
                rel="noopener"
              >
                {translate('terms')}
              </Link>

              <span>•</span>

              <Link
                href="https://rewardup.com/privacy"
                color="inherit"
                variant="body2"
                target="_blank"
                rel="noopener"
              >
                {translate('privacy_policy')}
              </Link>
            </Stack>

            <Typography variant="caption">{translate('copyright')}</Typography>
          </Container>
        </Box>
      </StyledContent>
    </StyledRoot>
  );
}
