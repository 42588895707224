import { IconButton, Link, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PATH_SETTINGS } from '../../routes/paths';
import { useLocales } from '../../locales';
import { useAuthContext } from '../../auth/useAuthContext';
import { useBoolean } from '../../hooks/useBoolean';
import Iconify from '../../components/iconify';
import { WarningIcon } from '../../theme/overrides/CustomIcons';

// ----------------------------------------------------------------------

export default function TfnAlert() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const { translate } = useLocales();

  const { business } = useAuthContext();

  const open = useBoolean(true);

  if (
    business?.allow_sms &&
    business?.sms_channel !== 'twilio' &&
    !business?.bandwidth?.phone_number &&
    open.value
  ) {
    return (
      <Stack
        sx={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 1200,
          backgroundColor: theme.palette.warning[isLight ? 'lighter' : 'darker'],
          color: theme.palette.warning[isLight ? 'darker' : 'lighter'],
          p: 1,
        }}
        direction="row"
        spacing={1}
        justifyContent="center"
      >
        <Stack
          direction="row"
          spacing={1}
          flexGrow={1}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
        >
          <WarningIcon color="warning" />

          <Typography variant="body2" textAlign="center">
            {translate('toll_free_number_verification_notification')}
          </Typography>

          <Link href={`${PATH_SETTINGS.account}?tab=sms`} variant="body2">
            {translate('verify_your_brand')}
          </Link>
        </Stack>

        <IconButton onClick={() => open.onFalse()}>
          <Iconify
            sx={{ color: theme.palette.warning[isLight ? 'darker' : 'lighter'] }}
            icon="mdi:close"
          />
        </IconButton>
      </Stack>
    );
  }

  return null;
}
