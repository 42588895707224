import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format, subYears } from 'date-fns';
// @mui
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// locales
import { useLocales } from '../../locales';
// utils
import { fDateApi } from '../../utils/formatTime';
import { pDate } from '../../utils/parseTime';

// ----------------------------------------------------------------------

const YearPicker = ({ value, onChange }) => {
  const { translate } = useLocales();

  const years = [];
  for (let i = subYears(new Date(), 1).getFullYear(); i > 1900; i -= 1) {
    years.push({
      label: `${i}`,
      value: i,
    });
  }

  return (
    <TextField
      fullWidth
      select
      label={translate('year')}
      value={value === -1 ? '' : value}
      onChange={(event) => onChange(Number(event.target.value))}
      SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 240 } } } }}
    >
      <MenuItem value={-1}>{translate('select')}</MenuItem>
      {years.map((year) => (
        <MenuItem key={year.value} value={year.value}>
          {year.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

YearPicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

// ----------------------------------------------------------------------

const MonthPicker = ({ year, value, onChange }) => {
  const { translate } = useLocales();

  const months = [];
  if (year !== -1) {
    for (let i = 0; i < 12; i += 1) {
      months.push({
        label: format(new Date(year, i, 1), 'LLL'),
        value: i,
      });
    }
  }

  return (
    <TextField
      fullWidth
      select
      label={translate('month')}
      value={value === -1 ? '' : value}
      onChange={(event) => onChange(Number(event.target.value))}
      SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 240 } } } }}
      disabled={year === -1}
    >
      <MenuItem value={-1}>{translate('select')}</MenuItem>
      {months.map((month) => (
        <MenuItem key={month.value} value={month.value}>
          {month.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

MonthPicker.propTypes = {
  year: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

// ----------------------------------------------------------------------

const DayPicker = ({ year, month, value, onChange }) => {
  const { translate } = useLocales();

  const days = [];
  if (year !== -1 && month !== -1) {
    const daysInAMonth = new Date(year, month + 1, 0).getDate();
    for (let i = 1; i <= daysInAMonth; i += 1) {
      days.push({
        label: `${i}`,
        value: i,
      });
    }
  }

  return (
    <TextField
      fullWidth
      select
      label={translate('day')}
      value={value === -1 ? '' : value}
      onChange={(event) => onChange(Number(event.target.value))}
      SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 240 } } } }}
      disabled={year === -1 || month === -1}
    >
      <MenuItem value={-1}>{translate('select')}</MenuItem>
      {days.map((day) => (
        <MenuItem key={day.value} value={day.value}>
          {day.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

DayPicker.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function BirthdayPicker({ value, onChange }) {
  const parsedDate = pDate(value) || null;

  const [year, setYear] = useState(parsedDate ? parsedDate.getFullYear() : -1);
  const [month, setMonth] = useState(parsedDate ? parsedDate.getMonth() : -1);
  const [day, setDay] = useState(parsedDate ? parsedDate.getDate() : -1);

  useEffect(() => {
    if (year !== -1 && month !== -1 && day !== -1) {
      onChange(fDateApi(new Date(year, month, day)));
    } else {
      onChange(null);
    }
  }, [year, month, day, onChange]);

  const onYearChange = (newValue) => {
    setYear(newValue);
    if (newValue === -1) {
      setMonth(-1);
      setDay(-1);
    }
  };

  const onMonthChange = (newValue) => {
    setMonth(newValue);
    if (newValue === -1) {
      setDay(-1);
    } else {
      const daysInAMonth = new Date(year, month, 0).getDate();
      if (day > daysInAMonth) {
        setDay(-1);
      }
    }
  };

  const onDayChange = (newValue) => {
    setDay(newValue);
  };

  return (
    <Stack sx={{ my: 1.5 }} direction="row" spacing={2} alignItems="center" justifyContent="center">
      <YearPicker value={year} onChange={onYearChange} />

      <MonthPicker year={year} value={month} onChange={onMonthChange} />

      <DayPicker year={year} month={month} value={day} onChange={onDayChange} />
    </Stack>
  );
}

BirthdayPicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};
