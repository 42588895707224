import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
// components
import BirthdayPicker from '../birthday-picker';

// ----------------------------------------------------------------------

export default function RHFBirthdayPicker({ name, label, helperText }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          {label && (
            <FormLabel sx={{ typography: 'body2', color: 'text.primary', fontWeight: 'bold' }}>
              {label}
            </FormLabel>
          )}

          <FormGroup>
            <BirthdayPicker value={field.value} onChange={field.onChange} />
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

RHFBirthdayPicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
};
