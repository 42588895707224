import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../config-global';
import { NotificationActions } from '../slice/notification';

// ----------------------------------------------------------------------

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryInterceptor = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(NotificationActions.handleUnauthorizeAccessAlert(result.error.data));
    api.dispatch({ type: 'UNAUTHORIZED_ACCESS', payload: undefined })
    return null;
}
  return result;
};

export const api = createApi({
  reducerPath: 'rupApi',
  baseQuery:baseQueryInterceptor,
  tagTypes: [
    'Countries',
    'Points Earning',
    'Points Redeeming',
    'Inventory Collections',
    'Inventory Items',
    'VIP',
    'Referrals',
    'Members',
    'Members Tags',
    'Members Stats',
    'Members Segments',
    'Member Rewards',
    'Member Redeem Reward',
    'Members Point Activities',
    'Member Point Activities',
    'Members Referral Activities',
    'Member Referral Activities',
    'Members VIP Activities',
    'Member VIP Activities',
    'Members Reward Redeemed Activities',
    'Member Reward Redeemed Activities',
    'Members Order Activities',
    'Member Order Activities',
    'Retention Autopilots',
    'Retention Autopilot Notification',
    'Vouchers',
    'Voucher Notification',
    'Email Notifications',
    'SMS Notifications',
    'Push Notifications',
    'Reward Wallet Passes',
    'Gift Wallet Passes',
    'Store Credit Wallet Passes',
    'Member Site',
    'Campaigns',
    'Subscription',
    'Subscription Products',
    'Customer Cards',
    'Invoices',
    'Gift Cards',
    'Gift Cards Overview',
    'Gift Cards Transactions',
    'Gift Card Transactions',
    'Gift Card Communications',
    'Gift Items',
    'Gift Setting',
    'Store Credit Cards',
    'Store Credit Cards Overview',
    'Store Credit Cards Transactions',
    'Store Credit Card Transactions',
    'Store Credit Card Communications',
    'Store Credit Setting',
    'Program Stats Report',
    'Signup Source Report',
    'User Activity Report',
    'Frequent Visitors Report',
  ],
  endpoints: () => ({}),
  // refetchOnFocus: true,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
});
