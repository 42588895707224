import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Box, Link} from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({mode = 'mark', disabledLink = false, sx}, ref) => {

  const logo = (
    <Box
      ref={ref}
      component="img"
      src={`/logo/logo_${mode}.svg`}
      sx={{
        width: mode === 'mark' ? 40 : undefined,
        height: mode === 'type' ? 32 : 40,
        cursor: 'pointer',
        ...sx,
      }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{display: 'contents'}}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  mode: PropTypes.oneOf(['mark', 'type']),
  disabledLink: PropTypes.bool,
};

export default Logo;
