import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Link, MenuItem, Stack, Typography } from '@mui/material';
import { PATH_AUTH } from '../../../routes/paths';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useLocales } from '../../../locales';
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { fPhoneNumber, getFullName } from '../../../utils/formatString';
import ConfirmDialog from '../../../components/dialog/ConfirmDialog';
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { translate } = useLocales();

  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);

  const [openLogoutConfirmationDialog, setOpenLogoutConfirmationDialog] = useState(false);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleCloseLogoutConfirmationDialog = () => {
    setOpenLogoutConfirmationDialog(false);
    handleClosePopover();
  };

  const handleOpenLogoutConfirmationDialog = () => {
    setOpenLogoutConfirmationDialog(true);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      enqueueSnackbar(translate('logout_error'), { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={user?.image_url}
          alt={`${user?.first_name} ${user?.last_name}`}
          name={`${user?.first_name} ${user?.last_name}`}
        />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} noWrap>
            {translate('logged_in_as')}
          </Typography>

          <Stack>
            <Typography variant="body2" noWrap>
              {getFullName(user?.first_name, user?.last_name)}
            </Typography>

            <Typography variant="body2" noWrap>
              {user?.email}
            </Typography>
          </Stack>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} noWrap>
            {translate('support')}
          </Typography>

          <Stack>
            <Link href="mailto:support@rewardup.com" variant="body2">
              support@rewardup.com
            </Link>

            <Link href="tel:+18665106677" variant="body2">
              {fPhoneNumber('+18665106677')}
            </Link>
          </Stack>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleOpenLogoutConfirmationDialog} sx={{ m: 1 }}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>

      {openLogoutConfirmationDialog && (
        <ConfirmDialog
          title={translate('logout')}
          content={translate('confirm_logout_desc')}
          open={openLogoutConfirmationDialog}
          onClose={handleCloseLogoutConfirmationDialog}
          onActionClick={handleLogout}
          actionButtonColor='error'
        />
      )}
    </>
  );
}
