import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Button, Typography, Stack } from '@mui/material';
// components
import PageHeader from '../page-header';
import { MotionContainer, varBounce } from '../animate';
// hooks
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------
RetryScreen.propTypes = {
  error: PropTypes.object,
};

export default function RetryScreen({ error }) {
  const { translate } = useLocales();

  const message = error?.data?.message || error?.message;

  return (
    <Stack
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <PageHeader title="Retry" />

      <MotionContainer style={{ display: 'contents' }}>
        <m.div variants={varBounce().in} style={{ margin: '10px 0' }}>
          <img alt="retry" height="80px" src="/assets/icons/components/ic_error.png" />
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            {translate('oops_error_occured')}
          </Typography>
        </m.div>

        {message && (
          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary', mb: 2 }}>{message}</Typography>
          </m.div>
        )}

        <Button
          size="large"
          variant="contained"
          onClick={() => {
            window.location.reload();
          }}
        >
          {translate('retry')}
        </Button>
      </MotionContainer>
    </Stack>
  );
}
