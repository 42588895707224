// ----------------------------------------------------------------------

export const LIST = 'LIST';
export const PAGINATED_LIST = 'PAGINATED-LIST';

export function providesList(resultsWithIds, tagType) {
  return resultsWithIds
    ? [{ type: tagType, id: LIST }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: LIST }];
}

export function providesPaginatedList(resultsWithIds, tagType) {
  return resultsWithIds
    ? [
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
        { type: tagType, id: PAGINATED_LIST },
      ]
    : [{ type: tagType, id: PAGINATED_LIST }];
}
