import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { api } from './services/api';
import { NotificationSlice } from './slice/notification';
// ----------------------------------------------------------------------

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  notification: NotificationSlice.reducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
    case 'UNAUTHORIZED_ACCESS':
      return appReducer({ ...state, rupApi: undefined }, action);

    case 'CLEAR':
      return appReducer(undefined, action);

    default:
      return appReducer(state, action);
  }
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});
