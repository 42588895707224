import { parsePhoneNumber } from 'libphonenumber-js';

// ----------------------------------------------------------------------

export const getFullName = (firstName, lastName) => `${firstName || ''} ${lastName || ''}`.trim();

const parseFirstAndLastName = (name) => {
  const nameArray = name.toString().split(' ');
  let first_name = '';
  let last_name;
  if (nameArray.length > 1) {
    for (let i = 0; i < nameArray.length - 1; i += 1) {
      first_name += ` ${nameArray[i]}`;
    }
    first_name = first_name.trim();
    last_name = nameArray[nameArray.length - 1];
  } else {
    first_name = name;
  }
  return { first_name, last_name };
};

export const getFirstName = (name) => parseFirstAndLastName(name).first_name;

export const getLastName = (name) => parseFirstAndLastName(name).last_name;

// ----------------------------------------------------------------------

export function fCardNumber(number) {
  const format = number.match(/[\s\S]{1,4}/g) || [];

  return format.join(' ');
}

export function fPhoneNumber(number, countryCode = 'CA') {
  if (!number) return number;
  return parsePhoneNumber(number, countryCode).formatNational();
}

export const fIcon = (icon, default_icon_url, custom_icon_url) => {
  if (icon === 'default') return default_icon_url;
  if (icon === 'custom') return custom_icon_url;
  return null;
};

export const fZipPostalCode = (code) => {
  if (!code) return code;

  if (Number(code) || code.length === 10) {
    return code.slice(0, 5);
  }

  return code
    .split(' ')
    .reduce((prevValue, currValue) => prevValue + currValue)
    .slice(0, 6).toUpperCase();
};
