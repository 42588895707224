import { createSlice } from '@reduxjs/toolkit';

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: {
    alerts: {
      isUnauthorizedUser: false,
      error: null,
    },
  },
  reducers: {
    handleUnauthorizeAccessAlert: (state, action) => ({
      isUnauthorizedUser: true,
      error: action?.payload ?? null,
    }),
    clear: () => ({
      isUnauthorizedUser: false,
      error: null,
    }),
  },
});

export const NotificationActions = NotificationSlice.actions;

export default NotificationSlice;
