// ----------------------------------------------------------------------

export default function buildQueryParams(params, jsonify = []) {
  const searchParams = new URLSearchParams();
  let hasValue = false;
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      hasValue = true;
      if (jsonify.includes(key)) {
        searchParams.append(key, JSON.stringify(params[key]));
      } else {
        searchParams.append(key, params[key]);
      }
    }
  });
  if (!hasValue) return '';
  return `?${searchParams.toString()}`;
}
