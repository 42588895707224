import PropTypes from 'prop-types';
import { format, setMinutes, startOfDay } from 'date-fns';
// @mui
import { MenuItem } from '@mui/material';
//
import { RHFSelect } from './RHFSelect';

// ----------------------------------------------------------------------

RHFTimePicker.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFTimePicker({ name, helperText, ...other }) {
  const getTimes = (start_time, end_time, interval) => {
    const times = [];
    for (let i = start_time; i <= end_time; i += interval) {
      times.push(i);
    }
    return times;
  };

  return (
    <RHFSelect name={name} helperText={helperText} {...other}>
      {getTimes(480, 1200, 15).map((time) => (
        <MenuItem key={time} value={time}>
          {format(setMinutes(startOfDay(new Date()), time), 'hh:mm a')}
        </MenuItem>
      ))}
    </RHFSelect>
  );
}
