import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
// hooks
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default function PageHeader({ title }) {
  const { translate } = useLocales();

  return (
    <Helmet>
      <title>
        {translate(title)} | {translate('app_name')}
      </title>
    </Helmet>
  );
}
