import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
// @mui
import { Stack, Typography, Button } from '@mui/material';
// hooks
import { useLocales } from '../../locales';
// layouts
import LoginLayout from '../../layouts/login';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// utils
import { fErrorMessage } from '../../utils/error';
// redux
import { store } from '../../redux/store';
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();

  const { logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const notificationAlert = useSelector((state) => state.notification);

  useEffect(() => {
    if (notificationAlert?.isUnauthorizedUser) {
      logout();

      const clearNotification = () => {
        store.dispatch({ type: 'CLEAR', payload: undefined });
      };

      enqueueSnackbar(fErrorMessage(notificationAlert?.error), {
        variant: 'error',
        onExited: clearNotification(),
      });
    }
  }, [enqueueSnackbar, notificationAlert, logout]);

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Stack>
          <Typography variant="h4">{translate('sign_in_welcome')}</Typography>

          <Typography variant="body1">{translate('sign_in_welcome_desc')}</Typography>
        </Stack>
      </Stack>

      <AuthLoginForm />

      <Stack sx={{ mt: 8 }}>
        <Typography textAlign="center" variant="body1" fontSize={13}>
          {translate('new_to_rewardup')}
        </Typography>
        <Button
          fullWidth
          color="inherit"
          size="large"
          variant="oultined"
          href="/register"
          sx={{
            mt: 1,
            border: (theme) => `1px solid ${theme.palette.grey[800]}`,
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
        >
          Sign Up
        </Button>
      </Stack>
    </LoginLayout>
  );
}
