export const restrictedWords = [
  'Vape',
  'Smoke',
  'Cigar',
  'Cigarette',
  'Cigars',
  'Cigarettes',
  'Cannabis',
  'Hemp',
  'Weed',
  'Marijuana',
  'Beer',
  'Alcohol',
  'Vodka',
  'Gin',
  'Whisky',
  'Brandy',
  'Liquor',
  'Kush',
  'Sex',
  'Firearms',
  'Gun',
  'Pistol',
  'Adult',
  'XXX',
  'Lottery',
  'Casino',
  'Tobacco',
  'Firearms',
  'CBD',
  'THC',
  'Vaping',
  'Kratom',
  'Paraphernalia',
  'E-Cigs',
  'E-ciggs',
  'Ecig',
  'E-cigarettes',
  'Pre-roll',
  'Preroll',
  'prerolls',
  'pre-rolls',
  'Edibles',
  'Indica',
  'Sativa',
  'Shatter',
  'Kief',
  'Ganja',
  'Dabs',
  'Concentrates',
  'Gummies',
  'gummiez',
  'Budtender',
  'Resin',
  'Bong',
  'vape pen',
  'e-juice',
  'ejuice',
  'c@nn@b!s',
  '$.aT!.vA',
  '!nd!ca',
  'prer0ll',
  'ku$h',
].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'accent' }));
