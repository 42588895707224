import PropTypes from 'prop-types';
// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  onActionClick: PropTypes.func,
  onClose: PropTypes.func,
  actionButtonColor: PropTypes.string,
  successText: PropTypes.string,
  cancelText: PropTypes.string,
  note: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default function ConfirmDialog({
  title,
  content,
  action,
  onActionClick,
  open,
  onClose,
  actionButtonColor,
  successText = 'Yes',
  cancelText = 'No',
  note,
  isLoading = false,
  ...other
}) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <Stack spacing={1}>
        {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

        {note && (
          <DialogContent sx={{ typography: 'caption' }}>
            <Alert sx={{ typography: 'caption' }} severity="warning">
              {note}
            </Alert>
          </DialogContent>
        )}
      </Stack>

      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          {cancelText}
        </Button>

        <LoadingButton
          variant="contained"
          color={actionButtonColor}
          onClick={onActionClick}
          loading={isLoading}
        >
          {successText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
