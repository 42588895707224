// ----------------------------------------------------------------------

export default function DatePicker() {
  return {
    [`MuiInputBase-inputAdornedEnd`]: {
      styleOverrides: {
        root: {
          width: '30rem',
          '@media (max-width: 900px)': {
            width: '100%',
          },
        },
      },
    },
  };
}
