import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { CircularProgress } from '@mui/material';
import { useUploadImageMutation } from '../../redux/services/businesses';
import { useLocales } from '../../locales';
import buildQueryParams from '../../utils/queryParams';
import { fErrorMessage } from '../../utils/error';

// ----------------------------------------------------------------------

EmailEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  properties: PropTypes.array,
};

export default function EmailEditor({ value, onChange, properties, ...other }) {
  const { translate } = useLocales();

  const [isLoaded, setLoaded] = useState(false);
  const editorRef = useRef(null);

  const [uploadImage] = useUploadImageMutation();

  const handleImageUpload = async (blobInfo) => {
    if (blobInfo.blob().size > 1024 * (1024 * 2)) {
      throw new Error('File size cannot be more than 2MB');
    }
    const formData = new FormData();
    formData.append('image', blobInfo.blob(), blobInfo.filename());
    try {
      const { image_url: link } = await uploadImage({
        queryParams: buildQueryParams({ type: 'campaign' }),
        body: formData,
      }).unwrap();
      return link;
    } catch (error) {
      throw new Error(fErrorMessage(error));
    }
  };

  return (
    <>
      {!isLoaded && <CircularProgress size={20} />}

      <Editor
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onLoadContent={() => {
          setLoaded(true);
        }}
        init={{
          height: 500,
          branding: false,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
          ],
          toolbar:
            'undo redo | blocks | bold italic forecolor | link | image | insert_property | ' +
            'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | noneditable_noneditable_class: mceNonEditable',
          toolbar_mode: 'wrap',
          image_uploadtab: true,
          image_dimensions: false,
          images_upload_handler: handleImageUpload,
          setup: (editor) => {
            if (properties) {
              editor.ui.registry.addMenuButton('insert_property', {
                text: translate('insert_property'),
                fetch: (callback) => {
                  callback(
                    properties.map((item) => ({
                      type: 'menuitem',
                      text: item,
                      onAction: (_) => editor.insertContent(item),
                    }))
                  );
                },
              });
            }
          },
          content_style: 'img {max-width: 100%; height: auto}',
        }}
        onNodeChange={(e) => {
          if (e && e.element.nodeName.toLowerCase() === 'img') {
            editorRef.current.dom.setAttribs(e.element, {
              'max-width': '100%',
              height: 'auto',
            });
          }
        }}
        value={value}
        onEditorChange={(content) => onChange(content)}
        {...other}
      />
    </>
  );
}
