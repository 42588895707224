import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// components
import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

GlobalGuard.propTypes = {
  children: PropTypes.node,
};

export default function GlobalGuard({ children }) {
  const { logout, isInitialized } = useAuthContext();

  const notificationAlert = useSelector((state) => state.notification);

  useEffect(() => {
    if (notificationAlert?.isUnauthorizedUser) {
      logout();
    }
  }, [notificationAlert, logout]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
