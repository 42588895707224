// @mui
import { Box, CardActionArea, styled, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledWrap = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'columnSpan',
})(({ columnSpan }) => ({
  gap: 16,
  display: 'grid',
  gridTemplateColumns: `repeat(${columnSpan || 2}, 1fr)`,
}));

// ----------------------------------------------------------------------

export const StyledCard = styled(CardActionArea, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  height: 92,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.grey[500], 0.30)}`,
  '& .svg-color': {
    width: 28,
    height: 28,
  },
  ...(selected && {
    color: theme.palette.primary.main,
    borderColor: alpha(theme.palette.primary.main, 0.36),
    boxShadow: theme.customShadows.primary,
  }),
}));

// ----------------------------------------------------------------------

export const StyledLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected, theme }) => ({
  textAlign: 'center',
  color: selected ? theme.palette.text.primary : theme.palette.text.secondary,
  fontWeight: selected ? '600' : '400',
}));
