import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays, isBefore, startOfDay } from 'date-fns';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  fullWidth: PropTypes.bool,
  disablePast: PropTypes.bool,
  disablePastWithoutToday: PropTypes.bool,
  showHighlightToday: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default function RHFDatePicker({
  name,
  helperText,
  fullWidth,
  disablePast,
  disablePastWithoutToday,
  showHighlightToday,
  readOnly = false,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          slotProps={{
            field: {
              clearable: true,
            },
            textField: {
              readOnly,
              fullWidth,
              error: !!error,
              helperText: error ? error?.message : helperText,
            },
          }}
          disablePast={disablePast}
          disableHighlightToday={showHighlightToday ? false : disablePast}
          shouldDisableDate={
            !showHighlightToday
              ? (date) => {
                  if (disablePast) {
                    return isBefore(startOfDay(date), new Date());
                  }
                  if (disablePastWithoutToday) {
                    return isBefore(addDays(startOfDay(date), 1), new Date());
                  }
                  return false;
                }
              : null
          }
          {...other}
        />
      )}
    />
  );
}
