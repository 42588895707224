import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { Navigate } from 'react-router-dom';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets/illustrations';
//
import { useAuthContext } from './useAuthContext';
import { PATH_AFTER_SETUP } from '../config-global';
import { PATH_MAIN } from '../routes/paths';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(PropTypes.string),
  restrictedPlans: PropTypes.arrayOf(PropTypes.string),
  restrictLegacyPlan: PropTypes.bool,
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  permissions,
  children,
  restrictedPlans,
  restrictLegacyPlan = false,
}) {
  // Logic here to get current user role
  const { businessRoles, role, businessPlan, business } = useAuthContext();

  const isRoleMatched = roles?.includes(role);

  const isMatchedWithAnyPermissions = permissions?.every((value) => businessRoles?.includes(value));

  const isFeatureGated =
    (restrictedPlans?.includes(businessPlan) ?? false) ||
    (restrictLegacyPlan && business?.is_legacy_billing_plan && businessPlan === 'premium');

  // Checks if the feature is restricted based on two conditions:
  // 1. The user's current business plan matches any of the restricted plans passed as prop
  // 2. The feature is restricted for legacy billing plans, and the user is on a legacy plan.

  const isNotUserPermitted = () =>
    typeof roles !== 'undefined' &&
    typeof permissions !== 'undefined' &&
    (!isRoleMatched || !isMatchedWithAnyPermissions);

  if (isNotUserPermitted() || isFeatureGated) {
    if (hasContent) {
      if (permissions?.find((o) => o === 'allow_getting_started')) {
        return <Navigate to={PATH_AFTER_SETUP} />;
      }

      if (['manager', 'marketing'].includes(role)) {
        return <Navigate to={PATH_MAIN.campaigns} />;
      }

      if (!businessRoles?.includes('members')) {
        return <Navigate to="/gift-card-program/gift-cards" />;
      }

      return (
        <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Permission Denied
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              You do not have permission to access this page
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>
        </Container>
      );
    }

    return null;
  }

  return <> {children} </>;
}
