import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
//
import { useCallback } from 'react';
import { Upload, UploadAvatar, UploadBox } from '../upload';

// ----------------------------------------------------------------------

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
};

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAvatar
            accept={{
              'image/*': [],
            }}
            error={!!error}
            file={field.value}
            {...other}
          />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

RHFUploadBox.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadBox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox files={field.value} error={!!error} {...other} />
      )}
    />
  );
}

// ----------------------------------------------------------------------

RHFUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  helperText: PropTypes.node,
};

export function RHFUpload({ name, label, multiple, helperText, ...other }) {
  const { control, unregister, setValue, watch } = useFormContext();

  const labelledby = label ? `${name}-${label}` : '';

  const preview = watch(`${name}_url`);

  const removedName = `remove_${name}`;
  const isRemoved = watch(removedName);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (newFile) {
        setValue(removedName, false);
        unregister(removedName);
        setValue(name, newFile, { shouldValidate: true });
      }
    },
    [name, removedName, unregister, setValue]
  );

  const handleRemoveFile = useCallback(() => {
    setValue(removedName, true);
    setValue(name, null, { shouldValidate: true });
  }, [name, removedName, setValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel
              component="legend"
              id={labelledby}
              sx={{ typography: 'caption', mb: 1, color: 'text.secondary' }}
            >
              {label}
            </FormLabel>
          )}

          {multiple ? (
            <Upload
              multiple
              accept={{ 'image/*': [] }}
              files={field.value}
              error={!!error}
              helperText={
                (!!error || helperText) && (
                  <FormHelperText error={!!error} sx={{ px: 2 }}>
                    {error ? error?.message : helperText}
                  </FormHelperText>
                )
              }
              {...other}
            />
          ) : (
            <Upload
              accept={{ 'image/*': [] }}
              file={field.value || (!isRemoved ? preview : null)}
              error={!!error}
              helperText={
                (!!error || helperText) && (
                  <FormHelperText error={!!error} sx={{ px: 2 }}>
                    {error ? error?.message : helperText}
                  </FormHelperText>
                )
              }
              onDrop={handleDrop}
              onDelete={handleRemoveFile}
              {...other}
            />
          )}
        </FormControl>
      )}
    />
  );
}
