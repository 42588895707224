export const fErrorResponse = (error) => ({
  ...error,
  message: error?.message,
});

export const fErrorMessage = (error) => {
  const data = error?.data || error;
  if (data.message) {
    if (Array.isArray(data.message) && data.message.length > 0) {
      return data.message[0];
    }
    return data.message;
  }
  return data.error;
};
