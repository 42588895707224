// components
import PageHeader from '../../components/page-header';
// sections
import Login from '../../sections/auth/Login';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <PageHeader title="login" />

      <Login />
    </>
  );
}
