import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number, hyphenFallback = false) {
  if (!number && hyphenFallback) {
    return '-';
  }
  return number < 100000
    ? numeral(number).format()
    : numeral(number)
        .format('0a')
        .toLowerCase()
        .replace(/[a-z]\b/g, (c) => c.toUpperCase());
}

export function fCurrency(number) {
  const format = Number.isFinite(Number(number)) ? numeral(number / 100).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fDollarToCent(dollar) {
  if (!dollar) return '';
  return Number(Number(dollar * 100).toFixed(0));
}

export function fCentToDollar(cent) {
  if (!cent) return '';
  const format = Number(cent / 100).toFixed(2);
  return result(format, '.00');
}

export function fPercent(number) {
  const format = numeral(Number(number) / 100).format('0.0%');
  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = numeral(number).format('0.00a');
  return result(format, '.00');
}

export function fData(number) {
  const format = numeral(number).format('0.0 b');
  return result(format, '.0');
}

export function fFileSize(bytes) {
  return numeral(bytes).format('0.0 b');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);
  return isInteger ? format.replace(key, '') : format;
}

export function fCentToDollarAsNumber(cent) {
  return Number(fCentToDollar(cent)) ?? 0;
}
