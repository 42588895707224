import PropTypes from 'prop-types';
import { useEffect } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormHelperText, InputLabel, Stack } from '@mui/material';
//
import EmailEditor from '../email-editor';

// ----------------------------------------------------------------------

RHFEmailEditor.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  properties: PropTypes.array,
  helperText: PropTypes.node,
};

export default function RHFEmailEditor({ name, label, properties, helperText, ...other }) {
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitSuccessful },
  } = useFormContext();

  const values = watch();

  useEffect(() => {
    if (values[name] === '<p></p>') {
      setValue(name, '', {
        shouldValidate: !isSubmitSuccessful,
      });
    }
  }, [isSubmitSuccessful, name, setValue, values]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack>
          {label && (
            <InputLabel id={name} shrink variant="standard">
              {label}
            </InputLabel>
          )}

          <EmailEditor
            value={field.value}
            onChange={field.onChange}
            properties={properties}
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </Stack>
      )}
    />
  );
}
