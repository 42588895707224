import { api } from './api';
import { PAGINATED_LIST } from '../../utils/rtkQuery';

// ----------------------------------------------------------------------

const businessesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMemberSite: build.query({
      providesTags: ['Member Site'],
      query: () => 'businesses/member-site',
      transformResponse: (response) => response.member_site,
    }),
    getCloverDevices: build.query({
      providesTags: ['Clover Devices'],
      query: () => 'clover/devices',
      transformResponse: (response) => response.devices,
    }),
    updateBusiness: build.mutation({
      query(body) {
        return {
          url: `businesses`,
          method: 'PUT',
          body,
        };
      },
    }),
    updateBrand: build.mutation({
      query(body) {
        return {
          url: `businesses/brand`,
          method: 'PUT',
          body,
        };
      },
    }),
    updateAlert: build.mutation({
      query(body) {
        return {
          url: `businesses/alert`,
          method: 'PUT',
          body,
        };
      },
    }),
    updateAutomatedSegment: build.mutation({
      query(body) {
        return {
          url: `businesses/automated-segment`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Members', id },
        { type: 'Members', id: PAGINATED_LIST },
        { type: 'Members Tags' },
        { type: 'Members Stats' },
        { type: 'Members Segments' },
      ],
    }),
    updateMemberSite: build.mutation({
      query(body) {
        return {
          url: `businesses/member-site`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [{ type: 'Member Site' }],
    }),
    uploadImage: build.mutation({
      query({ queryParams, body }) {
        return {
          url: `businesses/upload/image${queryParams || ''}`,
          method: 'POST',
          body,
        };
      },
    }),
    activateTollFree: build.mutation({
      query(body) {
        return {
          url: `businesses/activate-toll-free`,
          method: 'POST',
          body,
        };
      },
    }),
    resubmitTollFree: build.mutation({
      query(body) {
        return {
          url: `businesses/resubmit-toll-free`,
          method: 'POST',
          body,
        };
      },
    }),
    updateClover: build.mutation({
      query(body) {
        return {
          url: `businesses/clover`,
          method: 'PUT',
          body,
        };
      },
    }),
    syncCloverInventory: build.mutation({
      query() {
        return {
          url: `businesses/clover/sync-inventory`,
          method: 'POST',
        };
      },
    }),
    updateLocationClover: build.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `businesses/location/clover/${id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    getLocationCloverDevices: build.query({
      providesTags: ['Clover Devices'],
      query: ({ location_id }) => `clover/location/devices/${location_id}`,
      transformResponse: (response) => response.devices,
    }),
    disconnectCloverDevice: build.mutation({
      query({ location_id }) {
        return {
          url: `clover/disconnect/${location_id}`,
          method: 'PUT',
        };
      },
    }),
    syncCloverInventoryLocation: build.mutation({
      query({ id }) {
        return {
          url: `businesses/clover/sync-inventory/location/${id}`,
          method: 'POST',
        };
      },
    }),
    updateAutoRechargeSMSCredit: build.mutation({
      query(body) {
        return {
          url: 'businesses/auto-recharge-sms-credit',
          method: 'PUT',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetMemberSiteQuery,
  useGetCloverDevicesQuery,
  useUpdateBusinessMutation,
  useUpdateBrandMutation,
  useUpdateAlertMutation,
  useUpdateAutomatedSegmentMutation,
  useUpdateMemberSiteMutation,
  useUploadImageMutation,
  useActivateTollFreeMutation,
  useResubmitTollFreeMutation,
  useUpdateCloverMutation,
  useSyncCloverInventoryMutation,
  useUpdateLocationCloverMutation,
  useDisconnectCloverDeviceMutation,
  useGetLocationCloverDevicesQuery,
  useSyncCloverInventoryLocationMutation,
  useUpdateAutoRechargeSMSCreditMutation,
} = businessesApi;
