import PropTypes from 'prop-types';
import { useEffect } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  validateRestrictedWords: PropTypes.bool,
  showError: PropTypes.bool,
  removeSpecialCharaters: PropTypes.bool,
  isZeroValidation: PropTypes.bool,
  isAlphaNumeric: PropTypes.bool,
  isAlphabetic: PropTypes.bool,
  isTitleCase: PropTypes.bool,
  customValueFormatter: PropTypes.func,
  readOnly: PropTypes.bool,
  removeSpecialCharRegex: PropTypes.instanceOf(RegExp),
};

export default function RHFTextField({
  name,
  helperText,
  validateRestrictedWords,
  showError = true,
  removeSpecialCharaters = false,
  isZeroValidation = false,
  isAlphaNumeric = false,
  isAlphabetic = false,
  isTitleCase = false,
  customValueFormatter,
  readOnly = false,
  removeSpecialCharRegex = /[^\p{L}0-9$%&/{}:!_\s]/gu,
  ...other
}) {
  const {
    control,
    watch,
    trigger,
    formState: { isSubmitted },
    clearErrors,
  } = useFormContext();

  const fieldValue = watch(name);

  useEffect(() => {
    const handleZeroValidation = () => {
      if (fieldValue === 0) {
        trigger(name);
      } else {
        clearErrors(name);
      }
    };

    const handleRestrictedWordValidation = () => {
      if (fieldValue?.trim()?.length === 0) {
        clearErrors(name);
      } else {
        trigger(name);
      }
    };

    if (!isSubmitted) {
      if (validateRestrictedWords) handleRestrictedWordValidation();

      if (isZeroValidation) handleZeroValidation();
    }
  }, [
    trigger,
    name,
    validateRestrictedWords,
    isSubmitted,
    fieldValue,
    clearErrors,
    isZeroValidation,
  ]);

  const { maxLength } = other;

  const replaceSpecialCharaters = (str) =>
    typeof str === 'string' ? str?.replace(removeSpecialCharRegex, '') : str;

  const replaceNonAlphabeticCharacters = (str) =>
    typeof str === 'string' ? str.replace(/[^a-zA-Z ]/g, '') : str;

  const titleCase = (text) => text.replace(/\b\w/g, (char) => char.toUpperCase());

  const formatValue = (value) => {
    if (customValueFormatter) {
      return customValueFormatter(value);
    }

    if (typeof value === 'number' && value === 0) {
      return '';
    }

    if (isAlphaNumeric) return value.replace(/[^a-zA-Z0-9]/g, '');

    if (isAlphabetic) return replaceNonAlphabeticCharacters(value);

    if (isTitleCase) value = titleCase(value);

    return removeSpecialCharaters ? replaceSpecialCharaters(value) : value;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          onChange={(e) => {
            field.onChange(formatValue(e.target.value));
          }}
          error={!!error}
          helperText={error && showError ? error.message : helperText}
          inputProps={{
            maxLength,
            step: 1,
          }}
          InputProps={{
            readOnly,
          }}
          {...other}
        />
      )}
    />
  );
}
