import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  propertyKey: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.node,
  tagsFilter: PropTypes.bool,
};

export default function RHFAutocomplete({
  name,
  label,
  placeholder,
  helperText,
  tagsFilter = false,
  ...other
}) {
  const { control, setValue } = useFormContext();

  const handleOnChange = (event, newValue) => {
    if (tagsFilter) {
      const trimmedValue =
        typeof newValue === 'object' ? newValue?.filter((e) => e?.trim()?.length > 0) : newValue;
      setValue(name, trimmedValue, { shouldValidate: true });
      return;
    }
    setValue(name, newValue, { shouldValidate: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          fullWidth
          onChange={handleOnChange}
          renderInput={(params) => (
            <TextField
              label={label}
              error={!!error}
              helperText={error ? error?.message : helperText}
              placeholder={placeholder}
              {...params}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
