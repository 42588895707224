import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, Chip } from '@mui/material';

// ----------------------------------------------------------------------

RHFChip.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFChip({ name, helperText, ...other }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <Chip
            {...field}
            color={field.value ? 'primary' : undefined}
            onClick={() => setValue(field.name, !field.value)}
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}
