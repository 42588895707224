import { m } from 'framer-motion';
import { useEffect, useState } from 'react';
// @mui
import { Typography } from '@mui/material';
// components
import PageHeader from '../components/page-header';
import { MotionContainer, varBounce } from '../components/animate';
// ----------------------------------------------------------------------

export default function WebhookRedirect() {
  const [headerName, setHeaderName] = useState('Redirecting to Rewardup');

  const handleDelayCloseTab = () => {
    setTimeout(() => {
      window.close();
    }, 2000);
  };

  useEffect(() => {
    const handlePostMessageAction = async () => {
      const ru_channel = await new Promise((res) => {
        const key = localStorage.getItem('ru_channel');
        res(key);
      });
      if (ru_channel) {
        const channel = new BroadcastChannel(ru_channel);
        const urlParsed = new URL(window.location.href);
        const rewardupRedirectURL = urlParsed?.searchParams?.get('rewardupRedirectURL');

        if (rewardupRedirectURL) {
          window.location.replace(rewardupRedirectURL);
          return;
        }

        const merchant_id = urlParsed?.searchParams?.get('merchant_id');
        const client_id = urlParsed?.searchParams?.get('client_id');
        const code = urlParsed?.searchParams?.get('code');
        const employee_id = urlParsed?.searchParams?.get('employee_id');
        const state = urlParsed?.searchParams?.get('state');
        if (merchant_id && client_id && code && employee_id) {
          setHeaderName('Clover account connected');
          channel.postMessage(JSON.stringify({ merchant_id, client_id, code, employee_id, state }));
          handleDelayCloseTab();
        }
      }
    };
    handlePostMessageAction();
  }, []);

  return (
    <>
      <PageHeader title="connecting_to_clover" />

      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="body1" paragraph>
            {headerName}
          </Typography>
        </m.div>

        <Typography variant="body2" paragraph>
          You can close this window now.
        </Typography>
      </MotionContainer>
    </>
  );
}
