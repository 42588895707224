import PropTypes from 'prop-types';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { ButtonBase, InputAdornment, Popover, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledBoxColor = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color, theme }) => ({
  width: '32px',
  height: '32px',
  backgroundColor: color || theme.palette.primary.main,
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  color: 'inherit',
}));

// ----------------------------------------------------------------------

RHFColorPicker.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFColorPicker({ name, helperText, ...other }) {
  const {
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color) => {
    setValue(name, color.hex);
    if (errors?.[name]) trigger(name);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            fullWidth
            error={!!error}
            helperText={error ? error?.message : helperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <StyledBoxColor color={field.value} onClick={handleClick} />
                </InputAdornment>
              ),
            }}
            {...other}
          />
          <Popover anchorEl={anchorEl} open={open} onClose={handleClose}>
            <SketchPicker color={field.value} onChange={handleColorChange} />
          </Popover>
        </>
      )}
    />
  );
}
