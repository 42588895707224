import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import LoadingScreen from '../components/loading-screen';
import RetryScreen from '../components/retry-screen';
//
import Login from '../pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, userAPIFailure, logout, isActiveBilling } =
    useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  const accessiblePaths = ['/settings/billing', '/settings/account'];

  const notificationAlert = useSelector((state) => state.notification);

  useEffect(() => {
    if (notificationAlert?.isUnauthorizedUser) {
      logout();
    }
  }, [notificationAlert, logout]);

  if (notificationAlert?.isUnauthorizedUser && !isAuthenticated) {
    return <Login />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (userAPIFailure) {
    return <RetryScreen error={userAPIFailure} />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (!isActiveBilling && !accessiblePaths.includes(pathname)) {
    return <Navigate to="/settings/billing" />;
  }

  return <> {children} </>;
}
