import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

RHFNumberField.propTypes = {
  mode: PropTypes.oneOf(['any', 'whole']),
  name: PropTypes.string,
  helperText: PropTypes.node,
  isZeroValidation: PropTypes.bool,
};

export default function RHFNumberField({
  mode = 'any',
  name,
  helperText,
  isZeroValidation = false,
  ...other
}) {
  const {
    control,
    setValue,
    watch,
    trigger,
    formState: { isSubmitted },
    clearErrors,
  } = useFormContext();

  const changedValue = watch(name);

  useEffect(() => {
    const handleZeroValidation = () => {
      if (changedValue === 0) {
        trigger(name);
      } else {
        clearErrors(name);
      }
    };

    if (isZeroValidation && !isSubmitted) {
      handleZeroValidation();
    }

    if (Number.isFinite(changedValue)) {
      setValue(name, parseInt(changedValue, 10));
    }
  }, [name, changedValue, setValue, isZeroValidation, isSubmitted, trigger, clearErrors]);

  const formatValue = (value) => {
    if (typeof value === 'number' && value === 0 && isZeroValidation) {
      return '';
    }

    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          type="number"
          value={formatValue(field.value)}
          fullWidth
          error={!!error}
          helperText={error ? error?.message : helperText}
          onWheel={(event) => event.target.blur()}
          {...other}
        />
      )}
    />
  );
}
